import { useState, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "contexts/AuthContext";
import AccountContext from "contexts/AccountContext";
import { apiRequest } from "common/apiUtils";
import { CloseIcon, CheckCircleIcon, AlertTriangleIcon } from "assets/svgs";
import BasicSelect from "components/common/FormField/BasicSelect";

/**
 * CopyToModal Component
 *
 * A modal dialog that allows users to copy items between sandboxes.
 * Displays item details and provides a dropdown to select the destination sandbox.
 *
 * @param {Object} props
 * @param {boolean} props.isOpen - Controls modal visibility
 * @param {Function} props.onClose - Callback to close the modal
 * @param {Object} props.selectedItem - The item to be copied (contains loot_id, name, description, etc.)
 * @param {string} props.currentSandboxId - ID of the current sandbox
 * @param {Function} props.onSuccess - Callback for successful copy operation
 * @param {Function} props.onError - Callback for error handling
 * @param {string} [props.friendlyName="Item"] - Display name for the item type
 */
export default function CopyToModal({
  isOpen,
  onClose,
  selectedItem,
  currentSandboxId,
  onSuccess,
  pageData,
  onError,
  friendlyName = "Item",
}) {
  const [step, setStep] = useState("select"); // 'select', 'confirm', 'result'
  const [selectedSandbox, setSelectedSandbox] = useState(null);
  const [copyResult, setCopyResult] = useState(null);
  const [errors, setErrors] = useState(null);
  const [existingItem, setExistingItem] = useState(null);
  const { getAccessToken } = useAuth();
  const { currentStateData } = useContext(AccountContext);

  // Update useEffect to ONLY reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setStep("select");
      setSelectedSandbox(null);
      setCopyResult(null);
      setErrors(null);
      setExistingItem(null);
    }
  }, [isOpen]); // Only depend on isOpen

  // Filter available sandboxes: exclude current sandbox, readonly sandboxes, and archived sandboxes
  const availableSandboxes =
    currentStateData?.product?.sandboxes?.filter(
      (sandbox) =>
        sandbox.sandboxId !== currentSandboxId && // Exclude current sandbox
        !sandbox.readonly && // Exclude readonly sandboxes
        !sandbox.archive, // Exclude archived sandboxes
    ) || [];

  // Format sandboxes for BasicSelect component
  const sandboxOptions = availableSandboxes.map((sandbox) => ({
    id: sandbox.sandboxId,
    title: `${sandbox.sandboxName} (${sandbox.shortName})`,
  }));

  const handleSandboxChange = (e) => {
    const selectedId = e.target.value;
    // Find the complete sandbox object from availableSandboxes
    const sandbox = availableSandboxes.find((s) => s.sandboxId === selectedId);

    // Log for debugging
    console.debug("Sandbox selected:", { selectedId, sandbox });

    // Ensure we always set a complete sandbox object or null
    setSelectedSandbox(selectedId ? sandbox : null);
  };

  // Add a useEffect to validate selectedSandbox state
  useEffect(() => {
    if (selectedSandbox && !selectedSandbox.sandboxId) {
      console.error("Invalid sandbox state detected:", selectedSandbox);
      setSelectedSandbox(null);
    }
  }, [selectedSandbox]);

  // Add these helper functions near the top of the component
  const getObjectType = (pageData) => {
    // Return lowercase version of the Name from pageData
    return pageData?.Name?.toLowerCase();
  };

  const getObjectIdKey = (pageData) => {
    // Get the RowKey from pageData
    return pageData?.RowKey;
  };

  const getLegacyIdKey = (pageData) => {
    // Get the LegacyRowKey from pageData
    return pageData?.LegacyRowKey;
  };

  // Update the existing getObjectId function
  const getObjectId = (object, pageData) => {
    const idKey = getObjectIdKey(pageData);
    return object[idKey];
  };

  // Update the getApiPath function
  const getApiPath = (pageData) => {
    const objectType = getObjectType(pageData);
    const idKey = getObjectIdKey(pageData);
    return `/v1/sandbox/{sandbox_id}/${objectType}/{${idKey}}`;
  };

  /**
   * Checks if an object already exists in target sandbox
   */
  const checkExistingObject = async (sandboxId) => {
    if (!pageData) {
      console.error("pageData is required for checking existing objects");
      return { exists: false };
    }

    try {
      const objectType = getObjectType(pageData);
      const idKey = getObjectIdKey(pageData);
      const legacyIdKey = getLegacyIdKey(pageData);

      // Check for existing primary ID
      const primaryIdResponse = await apiRequest(
        await getAccessToken(),
        {
          path: `/v1/sandbox/{sandbox_id}/${objectType}`,
          method: "GET",
        },
        {
          sandbox_id: selectedSandbox?.sandboxId,
        },
        null,
        null,
        {
          queryParams: `${idKey}s=${getObjectId(selectedItem, pageData)}`,
        },
      );

      // Check for existing legacy ID if one exists
      let legacyIdResponse = { data: [] };
      const legacyId = selectedItem[legacyIdKey];

      if (legacyId) {
        legacyIdResponse = await apiRequest(
          await getAccessToken(),
          {
            path: `/v1/sandbox/{sandbox_id}/${objectType}`,
            method: "GET",
          },
          {
            sandbox_id: selectedSandbox?.sandboxId,
          },
          null,
          null,
          {
            queryParams: `${legacyIdKey}s=${legacyId}`,
          },
        );
      }

      // If either query returns results, object already exists
      if (primaryIdResponse.data.length > 0 || legacyIdResponse.data.length > 0) {
        const existingType = primaryIdResponse.data.length > 0 ? "primary_id" : "legacy_id";
        const existingId = primaryIdResponse.data.length > 0 ? getObjectId(selectedItem, pageData) : legacyId;

        return {
          exists: true,
          error: {
            error_code: "object_already_exists",
            desc: `${existingType} ${existingId} already exists in target sandbox (${selectedSandbox.sandboxName})`,
            status: 409,
            statusText: "Conflict",
          },
        };
      }

      return { exists: false };
    } catch (error) {
      console.error("Error checking existing object:", error);
      return { exists: false }; // Continue with copy attempt if check fails
    }
  };

  /**
   * Handles the copy operation
   */
  const handleCopy = async () => {
    if (!selectedSandbox?.sandboxId) return;

    // Check for existing object first
    const existingCheck = await checkExistingObject(selectedSandbox.sandboxId);

    if (existingCheck.exists) {
      setExistingItem(existingCheck.error);
      setStep("confirm");
      return;
    }

    // Continue with copy operation
    await performCopy();
  };

  const performCopy = async () => {
    if (!selectedSandbox?.sandboxId) {
      onError("No sandbox selected");
      return;
    }

    if (!pageData) {
      onError("Missing required configuration data");
      return;
    }

    const cleanPayload = Object.entries(selectedItem).reduce((acc, [key, value]) => {
      if (value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    try {
      const objectType = getObjectType(pageData);
      const objectIdKey = getObjectIdKey(pageData);

      const apiPath = getApiPath(pageData).replace("{sandbox_id}", selectedSandbox.sandboxId);

      const response = await apiRequest(
        await getAccessToken(),
        {
          path: apiPath,
          method: "PUT",
        },
        {
          sandbox_id: selectedSandbox.sandboxId,
          [objectIdKey]: getObjectId(selectedItem, pageData),
        },
        cleanPayload,
      );

      setCopyResult(response);
      setStep("result");
      onSuccess(
        `${friendlyName} ${existingItem ? "updated" : "copied"} successfully in ${selectedSandbox.sandboxName}!`,
      );
    } catch (error) {
      const errorData = error.errorMsg ? JSON.parse(error.errorMsg) : {};
      setErrors([
        {
          status: error.response?.status,
          statusText: error.response?.statusText,
          ...errorData,
        },
      ]);
      setStep("result");
      onError(errorData.desc || error.message);
    }
  };

  const formatErrorMessage = (error) => {
    // Add handling for already exists error
    if (error.error_code === "object_already_exists") {
      return error.desc;
    }
    // Format not found messages
    if (error.error_code === "loot_item_not_found") {
      const objectType = getObjectType(pageData);
      const idKey = getObjectIdKey(pageData);
      const idLabel = pageData?.Columns?.[idKey]?.title || "ID";

      return `${friendlyName} (${getObjectId(selectedItem, pageData)}) was not found in the destination sandbox`;
    }
    return error.desc;
  };

  const handleClose = () => {
    onClose();
  };

  const renderContent = () => {
    if (step === "select") {
      const objectType = getObjectType(pageData);
      const idKey = getObjectIdKey(pageData);
      const legacyIdKey = getLegacyIdKey(pageData);

      // Get proper ID labels from pageData columns
      const idLabel = pageData?.Columns?.[idKey]?.title || "ID";
      const legacyIdLabel = pageData?.Columns?.[legacyIdKey]?.title || "Legacy ID";

      return (
        <div>
          {/* Current Item Summary */}
          <div className="bg-black/20 rounded-lg border border-white/5 mb-4">
            <div className="px-4 py-2.5 border-b border-white/5">
              <h3 className="text-xs font-medium text-white/50 uppercase tracking-wider">
                Source {friendlyName} Details
              </h3>
            </div>

            {/* Details List */}
            <div className="divide-y divide-white/5">
              {/* IDs Row */}
              <div className="px-4 py-2.5 grid grid-cols-[1fr,auto] gap-6">
                <div>
                  <div className="text-xs font-medium text-white/40 mb-1">{idLabel}</div>
                  <code className="text-sm text-white/90 font-mono bg-black/20 px-2.5 py-1.5 rounded block overflow-x-auto">
                    {getObjectId(selectedItem, pageData)}
                  </code>
                </div>
                <div>
                  <div className="text-xs font-medium text-white/40 mb-1">{legacyIdLabel}</div>
                  <code className="text-sm text-white/90 font-mono bg-black/20 px-2.5 py-1.5 rounded inline-block min-w-[80px] text-center">
                    {selectedItem[legacyIdKey] || "—"}
                  </code>
                </div>
              </div>

              {/* Name and Description */}
              <div className="px-4 py-2.5 space-y-3">
                <div>
                  <div className="text-xs font-medium text-white/40 mb-1">
                    {pageData?.Columns?.name?.title || "Name"}
                  </div>
                  <div className="text-sm text-white/90">{selectedItem?.name || "—"}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-white/40 mb-1">
                    {pageData?.Columns?.description?.title || "Description"}
                  </div>
                  <div className="text-sm text-white/90 max-h-20 overflow-y-auto">
                    {selectedItem?.description || "—"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Destination Selection */}
          <div className="bg-black/20 rounded-lg border border-white/5 mb-4">
            <div className="px-4 py-2.5 border-b border-white/5">
              <h3 className="text-xs font-medium text-white/50 uppercase tracking-wider">Select Destination</h3>
            </div>
            <div className="p-4">
              <BasicSelect
                label="Destination Sandbox *"
                value={selectedSandbox?.sandboxId || ""}
                onChange={handleSandboxChange}
                options={sandboxOptions}
                placeholder="Choose a sandbox..."
                required={true}
              />
            </div>
          </div>

          {/* Actions */}
          <div className="flex justify-end gap-3 pt-3 border-t border-white/5 mt-auto">
            <button
              onClick={onClose}
              className="px-4 py-2 rounded-md text-sm font-medium text-white/60 hover:text-white/90 hover:bg-white/5 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleCopy}
              disabled={!selectedSandbox}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-all
                ${
                  !selectedSandbox
                    ? "bg-violet-500/30 text-white/50 cursor-not-allowed"
                    : "bg-violet-500 text-white hover:bg-violet-400 active:bg-violet-600"
                }`}
            >
              {!selectedSandbox ? "Select a destination" : `Copy to ${selectedSandbox.sandboxName}`}
            </button>
          </div>
        </div>
      );
    }

    if (step === "confirm") {
      return (
        <div>
          <div className="bg-amber-500/10 rounded-lg border border-amber-500/20 p-4 mb-4">
            <div className="flex items-center gap-2 mb-3">
              <AlertTriangleIcon className="w-5 h-5 text-amber-500 flex-shrink-0" />
              <span className="font-medium text-amber-500">Item Already Exists</span>
            </div>
            <p className="text-sm text-white/90 mb-4">{existingItem?.desc}</p>
            <p className="text-sm text-white/70">Would you like to update the existing item?</p>
          </div>

          <div className="flex justify-end gap-3 pt-3 border-t border-white/5">
            <button
              onClick={() => setStep("select")}
              className="px-4 py-2 rounded-md text-sm font-medium text-white/60 hover:text-white/90 hover:bg-white/5 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={() => performCopy()}
              className="px-4 py-2 rounded-md text-sm font-medium bg-amber-500 text-white hover:bg-amber-400"
            >
              Yes, Update Item
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="px-4 py-2.5 border-b border-white/5">
          <h3 className="text-xs font-medium text-white/50 uppercase tracking-wider">COPY RESULT</h3>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="p-4 space-y-4">
            {errors ? (
              <>
                <div className="flex items-center gap-2">
                  <AlertTriangleIcon className="w-5 h-5 text-amber-500 flex-shrink-0" />
                  <span className="font-medium text-amber-500">
                    Copy Operation Failed (HTTP {errors[0].status} {errors[0].statusText})
                  </span>
                </div>

                <div className="bg-black/20 rounded-lg p-4 border border-white/5">
                  <div className="text-sm text-white/50 mb-2">Error Code:</div>
                  <pre className="text-sm text-white/90 font-mono whitespace-pre-wrap break-words bg-black/30 p-3 rounded">
                    {JSON.stringify(
                      {
                        error_code: errors[0].error_code,
                        desc: errors[0].desc,
                      },
                      null,
                      2,
                    )}
                  </pre>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center gap-2">
                  <CheckCircleIcon className="w-5 h-5 text-emerald-500" />
                  <span className="font-medium text-emerald-500">
                    Copy Operation Successful (HTTP {existingItem ? "200 OK" : "201 Created"})
                  </span>
                </div>

                <div className="bg-black/20 rounded-lg p-4 border border-white/5">
                  <div className="text-sm text-white/50 mb-2">Details:</div>
                  <div className="text-sm text-white/90">
                    Successfully {existingItem ? "updated" : "copied"} {friendlyName} record {selectedItem.loot_id} (
                    {selectedItem.name}) to {selectedSandbox.sandboxName}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex justify-end gap-3 p-4 border-t border-white/5">
          <button
            onClick={() => setStep("select")}
            className="px-4 py-2 rounded-md text-sm font-medium text-white/60 hover:text-white/90 hover:bg-white/5 transition-colors"
          >
            Back
          </button>
          <button
            onClick={handleClose}
            className="px-4 py-2 rounded-md text-sm font-medium bg-violet-500 text-white hover:bg-violet-400"
          >
            {errors ? "Close" : "Done"}
          </button>
        </div>
      </div>
    );
  };

  if (!isOpen) return null;

  return createPortal(
    <AnimatePresence mode="wait">
      <motion.div
        className="fixed inset-0 bg-black/80 flex items-center justify-center z-[100]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={(e) => {
          if (e.target === e.currentTarget) handleClose();
        }}
      >
        <motion.div
          className="p-[1px] rounded-lg bg-gradient-to-r from-pink-500 to-indigo-500 overflow-hidden"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
        >
          <div className="bg-zinc-900 rounded-lg w-[600px] max-h-[80vh] flex flex-col">
            {/* Header */}
            <div className="flex justify-between items-start p-6 pb-0">
              <div>
                <h2 className="text-lg font-medium text-white">Copy {friendlyName}</h2>
                <p className="text-sm text-white/60 mt-1">
                  {step === "select"
                    ? "Review the details and select a destination sandbox"
                    : step === "confirm"
                      ? "Item already exists. Confirm the update."
                      : "Review the copy operation result"}
                </p>
              </div>
              <button
                onClick={handleClose}
                className="text-white/50 hover:text-white/90 transition-colors p-1 hover:bg-white/10 rounded-full"
              >
                <CloseIcon className="w-5 h-5" />
              </button>
            </div>

            {/* Content */}
            <div className="p-6 overflow-y-auto min-h-0">{renderContent()}</div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>,
    document.body,
  );
}
