import { useLayout } from "contexts/LayoutContext";
import Tooltip from "components/common/FormField/Tooltip";

const BooleanInput = ({
  id,
  name,
  value,
  onChange,
  required,
  className,
  disabled = false,
  label,
  description,
  onCheckToEnable,
}) => {
  const { tableSize } = useLayout();

  return (
    <div className="mb-3 container-query-formfield">
      <div className="cq-wrapper flex" style={{ justifyContent: "flex-start" }}>
        {label && (
          <div
            className={`block font-medium text-zinc-300 font-lato ${
              tableSize === "text-xs" || tableSize === "text-sm" ? "text-xs" : "text-sm"
            }`}
          >
            <Tooltip title={description} placement="left" disableInteractive>
              <p className="mb-1 cq-label">
                <span>{`${label} ${required ? "*" : ""}`}</span>
              </p>
            </Tooltip>
          </div>
        )}
        <div className="flex items-stretch gap-1.5 justify-between w-full">
          <label className={`flex items-center ${disabled ? "opacity-50 pointer-events-none" : ""}`}>
            <div className="relative inline-flex items-center">
              <input
                type="checkbox"
                id={id}
                name={name}
                checked={value}
                onChange={(e) => onChange(e, name, "checkbox")}
                className="sr-only peer"
                required={required}
              />
              <div className="w-11 h-6 bg-gray-700 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                {value && (
                  <svg
                    className="absolute left-1 top-1 w-4 h-4 text-white z-10"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            </div>
          </label>
          {onCheckToEnable && (
            <label className="flex items-center p-1 border border-zinc-600 rounded-[4px]">
              <p
                className={`mx-1 text-left text-zinc-300 font-medium inline-block select-none ${tableSize === "text-xs" ? "text-xs" : "text-sm"}`}
              >
                Enable Override
              </p>
              <input
                className="accent-violet-600"
                type="checkbox"
                onChange={(e) => onCheckToEnable(e, name, value)}
                checked={!disabled ? true : false}
              />
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default BooleanInput;
