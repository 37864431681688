import { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { debounce } from "common/helpers";
import SandboxConfigMenu from "./SandboxConfigMenu";
import AccountContext from "contexts/AccountContext";
import SubMenu from "./SubMenu";
import logo from "assets/rally-here-logo.png";
import {
  CorporateFareIcon,
  CloudIcon,
  EsportsIcon,
  StorageIcon,
  MenuBookIcon,
  DownloadIcon,
  RoomPreferencesIcon,
  MenuExpandIcon,
  FingerPrintIcon,
  SettingsIcon,
  CollectionsIcon,
  HelpIcon,
} from "assets/svgs";

const isDev = window.env.REACT_APP_API_BASE_PATH !== "https://developer.rallyhere.gg/api";

const DOCS_LINK =
  process.env.NODE_ENV === "production"
    ? "https://docs.rallyhere.gg"
    : `${process.env.REACT_APP_API_BASE_PATH}`.replace("/api", "").replace("developer.", "docs.");

const SUPPORT_LINK = "https://rallyhere.atlassian.net/servicedesk/customer/portals";

const TOOLS_LINK = "/tools";

export default function Menu({ parentMenuOpen, forceMenu, toggleMenu }) {
  const history = useHistory();

  const [activeMenuElem, setActiveMenuElem] = useState();
  const [activeOrgs, setActiveOrgs] = useState();
  const [environmentMenu, setEnvironmentMenu] = useState([]);
  let isMobileSize = useRef(window.innerWidth <= 1024);

  const { orgsData, permissionData, currentStateData } = useContext(AccountContext);

  function handleToggleMenu() {
    toggleMenu();
  }

  function manageBrowserSize(size) {
    if (size === "desktop" && isMobileSize.current) {
      parentMenuOpen && parentMenuOpen(true);
      isMobileSize.current = false;
    } else if (size === "mobile" && !isMobileSize.current) {
      parentMenuOpen && parentMenuOpen(false);
      isMobileSize.current = true;
    }
  }

  function hasAnyPermission(hasPermissions, needsPermissions) {
    needsPermissions.push("globalAdmin:*:*");
    return hasPermissions && hasPermissions.some((permission) => needsPermissions.includes(permission));
  }

  useEffect(() => {
    setActiveMenuElem(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    if (!orgsData) return;

    const activeOrgs = Object.values(orgsData).filter((x) => !x.archive);

    setActiveOrgs(activeOrgs);
  }, [orgsData]);

  useEffect(() => {
    if (permissionData?.isGlobalAdmin) {
      const menuHeight = document.getElementById("admin-menu").scrollHeight;
      document.getElementById("main-menu").style.paddingBottom = `${menuHeight + 20}px`;
    }
  }, [permissionData]);

  useEffect(() => {
    parentMenuOpen && parentMenuOpen(window.innerWidth > 1024);
    window.addEventListener(
      "resize",
      debounce(() => {
        manageBrowserSize(window.innerWidth > 1024 ? "desktop" : "mobile");
      }, 75),
    );

    return window.removeEventListener(
      "resize",
      debounce(() => {
        manageBrowserSize(window.innerWidth > 1024 ? "desktop" : "mobile");
      }, 75),
    );
  }, []);

  useEffect(() => {
    let envMenuData = [];
    if (currentStateData?.environment) {
      envMenuData = [];

      if (
        hasAnyPermission(currentStateData?.environment?.permissions, [
          "environment:config:view",
          "environment:config:edit",
        ])
      ) {
        envMenuData.push({
          shortName: "control-panel",
          environment_settingsName: "Settings",
          environment_settingsId: "control-panel",
        });
      }
      if (hasAnyPermission(currentStateData?.product?.permissions, ["product:config:view", "product:config:edit"])) {
        envMenuData.push({
          shortName: "publishing-overrides",
          environment_settingsName: "Publishing Overrides",
          environment_settingsId: "publishing-overrides",
        });
        envMenuData.push({
          shortName: "scheduler",
          environment_settingsName: "Scheduler",
          environment_settingsId: "scheduler",
        });
      }
      if (
        hasAnyPermission(currentStateData?.environment?.permissions, [
          "environment:support:view",
          "environment:config:view",
          "environment:config:edit",
        ])
      ) {
        envMenuData.push({
          shortName: "legacy_dev_portal",
          environment_settingsName: "Support Portal",
          environment_settingsId: "legacy_dev_portal",
          externalLink: currentStateData?.environment?.environmentClassicSupportToolUrl,
          tokenQueryParam: "authToken",
        });
      }
      if (hasAnyPermission(currentStateData?.environment?.permissions, ["environment:analytics:view"])) {
        let baseUrl = currentStateData?.environment?.rhBaseUrl;
        envMenuData.push({
          shortName: "insights",
          environment_settingsName: "Insights",
          environment_settingsId: "insights",
          externalLink: `${baseUrl}/grafana/dashboards`,
          tokenQueryParam: "auth_token",
        });
      }
    }
    setEnvironmentMenu(envMenuData);
  }, [currentStateData?.environment]);

  return (
    <div
      className={`fixed h-full transition-all -translate-x-full overflow-x-visible flex-1 ${
        forceMenu ? "translate-x-0 w-menu" : "w-0"
      } transition-all z-30`}
    >
      <div className="bg-[#121212] h-full max-w-menu w-full border-box top-0 left-0 z-10 text-fontBase overflow-y-auto fixed">
        <Link to="/" className="flex align-center">
          <img className="w-full max-w-[140px] ml-3.5 px-2 py-[0.6rem] my-1" src={logo} alt="Rally Here Logo" />
        </Link>
        {permissionData && orgsData && (
          <>
            <div id="main-menu">
              {activeOrgs && (location.pathname.includes("/org/") || location.pathname.includes("/tools")) && (
                <SubMenu
                  linkPrefix={"/org"}
                  name={"Organizations"}
                  segmentName={"org"}
                  selected={currentStateData?.org !== undefined}
                  showSelected
                  data={activeOrgs}
                />
              )}
              {currentStateData?.org &&
                !currentStateData?.product &&
                hasAnyPermission(currentStateData?.org?.permissions, [
                  "accountOrg:config:edit",
                  "accountOrg:config:view",
                ]) && (
                  <SubMenu
                    linkPrefix={`/org/${currentStateData?.org.shortName}/user-management`}
                    name={"User Management"}
                    segmentName={"user-management"}
                    alwaysExpanded={true}
                    isAdmin
                    data={[
                      {
                        shortName: "users",
                        "user-managementName": "Users",
                        "user-managementId": "users",
                      },
                      {
                        shortName: "groups",
                        "user-managementName": "Groups",
                        "user-managementId": "groups",
                      },
                    ]}
                    icon={<RoomPreferencesIcon className="inline-block mr-2" />}
                  />
                )}
              {currentStateData?.org && (
                <SubMenu
                  linkPrefix={`/org/${currentStateData?.org.shortName}/product`}
                  name={"Products"}
                  segmentName={"product"}
                  selected={currentStateData?.product !== undefined}
                  showSelected
                  data={orgsData[currentStateData?.org.shortName]?.products}
                  icon={<EsportsIcon className={`inline-block mr-2`} />}
                />
              )}
              {currentStateData?.product && !currentStateData?.sandbox && !currentStateData?.environment && (
                <SubMenu
                  linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product.shortName}`}
                  name={"Credentials"}
                  segmentName={"credentials"}
                  isAdmin
                  data={[
                    {
                      shortName: "policies",
                      credentialsName: "Policies",
                      credentialsId: "policies",
                    },
                    {
                      shortName: "clients",
                      credentialsName: "Clients",
                      credentialsId: "clients",
                    },
                  ]}
                  icon={<FingerPrintIcon className={`inline-block mr-2`} />}
                />
              )}
              {currentStateData?.product &&
                !currentStateData?.sandbox &&
                currentStateData?.product?.environments.filter((x) => !x.archive).length > 0 && (
                  <SubMenu
                    linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product?.shortName}/environment`}
                    name={"Environments"}
                    segmentName={"environment"}
                    selected={currentStateData?.environment !== undefined}
                    showSelected
                    data={
                      orgsData[currentStateData?.org.shortName]?.keyedProducts?.[currentStateData?.product.shortName]
                        ?.environments
                    }
                    icon={<CollectionsIcon className={`inline-block mr-2`} />}
                  />
                )}
              {currentStateData?.environment && (
                <SubMenu
                  linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product?.shortName}/environment/${currentStateData?.environment?.shortName}`}
                  name={"Environment Menu"}
                  segmentName={"environment_settings"}
                  isAdmin
                  data={environmentMenu}
                />
              )}
              {currentStateData?.environment &&
                (permissionData?.isGlobalAdmin ||
                  (hasAnyPermission(currentStateData?.environment?.permissions, ["environment:analytics:view"]) &&
                    currentStateData?.product?.shortName === "smite2")) && (
                  <SubMenu
                    linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product?.shortName}/environment/${currentStateData?.environment?.shortName}/tableau`}
                    name={"Tableau"}
                    segmentName={"tableau"}
                    isAdmin={true}
                    data={[
                      {
                        shortName: "Live Executive Dashboard",
                        tableauName: "Live Executive Dashboard",
                        tableauId: "Live Executive Dashboard",
                      },
                      {
                        shortName: "Active Users",
                        tableauName: "Active Users",
                        tableauId: "Active Users",
                      },
                      {
                        shortName: "Sales Dashboard",
                        tableauName: "Sales Dashboard",
                        tableauId: "Sales Dashboard",
                      },
                    ]}
                  />
                )}
              {currentStateData?.product &&
                currentStateData?.product.sandboxes.length > 0 &&
                !currentStateData?.environment && (
                  <SubMenu
                    linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product.shortName}/sandbox`}
                    name={"Sandboxes"}
                    segmentName={"sandbox"}
                    selected={currentStateData?.sandbox !== undefined}
                    showSelected
                    data={orgsData[currentStateData?.org.shortName]?.keyedProducts?.[
                      currentStateData?.product.shortName
                    ]?.sandboxes?.filter((sandbox) => !sandbox.readonly)}
                    icon={<StorageIcon className={`inline-block mr-2`} />}
                  />
                )}
              {currentStateData?.sandbox && (
                <>
                  <h3 className="text-white font-bold text-sm uppercase mt-3 mb-1 px-6 py-1 opacity-40">
                    Sandbox Configuration
                  </h3>
                  <SandboxConfigMenu />
                  <div className="h-[1px] w-full mt-2">
                    <div className="h-full mx-4 bg-white/10" />
                  </div>
                </>
              )}
              <div className="mt-2">
                {location.pathname.includes("/admin") && (
                  <>
                    <SubMenu
                      linkPrefix={"/admin"}
                      name={"Admin"}
                      segmentName={"admin"}
                      selected={true}
                      icon={<CorporateFareIcon className={`inline-block mr-2`} />}
                      isAdmin
                      data={[
                        {
                          shortName: "dashboard",
                          adminName: "Dashboard",
                          adminId: "dashboard",
                        },
                        {
                          shortName: "orgstructure",
                          adminName: "Org Structure",
                          adminId: "orgstructure",
                        },
                        {
                          shortName: "permissions",
                          adminName: "Permissions",
                          adminId: "permissions",
                        },
                      ]}
                    />
                    {permissionData.isGlobalAdmin && (
                      <SubMenu
                        linkPrefix={"/admin/armada"}
                        name={"Armada"}
                        segmentName={"armada"}
                        selected={true}
                        icon={<CloudIcon className={`inline-block mr-2`} />}
                        isAdmin
                        data={[
                          {
                            shortName: "geo-location",
                            armadaName: "Geo Location",
                            armadaId: "geo-location",
                          },
                          {
                            shortName: "dc-provider",
                            armadaName: "Datacenter Providers",
                            armadaId: "dc-provider",
                          },
                          {
                            shortName: "dc-location",
                            armadaName: "Datacenter Locations",
                            armadaId: "dc-location",
                          },
                          {
                            shortName: "dc-contract",
                            armadaName: "Datacenter Contracts",
                            armadaId: "dc-contract",
                          },
                          {
                            shortName: "machine-image",
                            armadaName: "Machine Images",
                            armadaId: "machine-image",
                          },
                          {
                            shortName: "machine-class",
                            armadaName: "Machine Classes",
                            armadaId: "machine-class",
                          },
                          {
                            shortName: "product-assignment",
                            armadaName: "Simple Product Assignments",
                            armadaId: "product-assignment",
                          },
                          {
                            shortName: "host",
                            armadaName: "Hosts",
                            armadaId: "host",
                          },
                          {
                            shortName: "host-by-label",
                            armadaName: "Hosts By Label",
                            armadaId: "host-by-label",
                          },
                          {
                            shortName: "client-credentials",
                            armadaName: "Client Credentials",
                            armadaId: "client-credentials",
                          },
                        ]}
                      />
                    )}

                    {permissionData.isGlobalAdmin && (
                      <SubMenu
                        linkPrefix={"/admin/terraform"}
                        name={"Terraform"}
                        segmentName={"terraform"}
                        selected={true}
                        icon={<CloudIcon className={`inline-block mr-2`} />}
                        isAdmin
                        data={[
                          {
                            shortName: "terraform-portal",
                            terraformName: "Terraform Portal",
                            terraformId: "terraform-portal",
                          },
                          {
                            shortName: "vm-size",
                            terraformName: "Virtual Machine Size",
                            terraformId: "vm-size",
                          },
                          {
                            shortName: "vm-disk",
                            terraformName: "Virtual Machine Disk Type",
                            terraformId: "vm-disk",
                          },
                          {
                            shortName: "vm-region",
                            terraformName: "Virtual Machine Region",
                            terraformId: "vm-region",
                          },
                          {
                            shortName: "vm-os",
                            terraformName: "Virtual Machine OS",
                            terraformId: "vm-os",
                          },
                          {
                            shortName: "vm-rg",
                            terraformName: "Virtual Machine Resource Group",
                            terraformId: "vm-rg",
                          },
                          {
                            shortName: "vm-subnet",
                            terraformName: "Virtual Machine Subnet Range",
                            terraformId: "vm-subnet",
                          },
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              id="admin-menu"
              className="bg-[#121212] fixed bottom-0 pb-4 left-0 w-full max-w-menu border-t border-fontBase/10 overflow-hidden py-1"
            >
              <div className="flex flex-col px-4">
                <a
                  href={SUPPORT_LINK}
                  className="py-1.5 hover:bg-[#9d69ff29] transition-colors rounded-md"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="px-3 flex items-center justify-start w-full">
                    <HelpIcon className="w-4 h-4 mr-2 text-gray-400 group-hover:text-white" />
                    <span className="text-gray-300 group-hover:text-white text-sm font-regular">Contact Support</span>
                  </span>
                </a>

                <a
                  href={DOCS_LINK}
                  className="px-3 py-2 hover:bg-[#9d69ff29] transition-colors rounded-md"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="flex items-center justify-start w-full">
                    <MenuBookIcon className="w-4 h-4 mr-2 text-gray-400 group-hover:text-white" />
                    <span className="text-gray-300 group-hover:text-white text-sm font-regular">Documentation</span>
                  </span>
                </a>
                <a
                  href="https://github.com/RallyHereInteractive/unreal-plugins/releases/latest"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-3 py-2 hover:bg-[#9d69ff29] transition-colors rounded-md"
                >
                  <span className="flex items-center justify-start w-full">
                    <DownloadIcon className="w-4 h-4 mr-2 text-gray-400 group-hover:text-white" />
                    <span className="text-gray-300 group-hover:text-white text-sm font-regular">Download SDK</span>
                  </span>
                </a>
                <a
                  href={TOOLS_LINK}
                  className="px-3 py-2 hover:bg-[#9d69ff29] transition-colors rounded-md"
                  rel="noopener noreferrer"
                >
                  <span className="flex items-center justify-start w-full">
                    <SettingsIcon className="w-4 h-4 mr-2 text-gray-400 group-hover:text-white" />
                    <span className="text-gray-300 group-hover:text-white text-sm font-regular">Tools</span>
                  </span>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
