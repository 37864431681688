import { useState, useMemo } from "react";
import { createPortal } from "react-dom";
import { useLayout } from "contexts/LayoutContext";
import pageData from "common/pageConfig.json";
import { MinusIcon as CloseIcon } from "assets/svgs";
import { AnimatePresence, motion } from "framer-motion";

import FormField from "components/common/FormField";
import MenuBar from "components/common/MenuBar";
import Modal from "components/common/Modal";
import BasicSelect from "components/common/FormField/BasicSelect";
import definitions from "common/definitions.json";

function getEditorConfig(config, editorTab) {
  return config?.editor[editorTab] ? config?.editor[editorTab] : undefined;
}

export default function OverridesForm({
  item,
  config,
  tab,
  onChange,
  newModel,
  buttonsMap,
  readPermitted,
  editPermitted,
  sandboxId,
  onDelete,
  showModal,
  setShowModal,
  onCheckToEnable,
  enabledFields,
  deleteOverrideFields,
  formClassName,
  // onRevertToBase,
  rowOverrides,
}) {
  const { tableSize } = useLayout();

  const tabs = Object.keys(config?.editor);
  const defaultTab = useMemo(() => {
    const editorConfig = config?.editor || {};
    return Object.keys(editorConfig).find((key) => editorConfig[key]?.default) ?? Object.keys(editorConfig)[0];
  }, [config]);

  const [editorTab, setEditorTab] = useState(defaultTab);
  const editorConfig = getEditorConfig(config, editorTab);

  // Separate metadata fields from regular fields
  const { metadataFields, editableFields } = useMemo(() => {
    if (!editorConfig?.fields) return { metadataFields: [], editableFields: [] };

    return editorConfig.fields.reduce(
      (acc, field) => {
        if (field.type === "metadata" || field.field === "name" || field.field === "key" || field.field === "id") {
          acc.metadataFields.push(field);
        } else {
          acc.editableFields.push(field);
        }
        return acc;
      },
      { metadataFields: [], editableFields: [] },
    );
  }, [editorConfig?.fields]);

  // Get available fields for dropdown (fields not currently active)
  const selectableFields = useMemo(() => {
    return editableFields
      .filter((field) => !enabledFields.has(field.field))
      .map((field) => ({
        id: field.field,
        title: field.headerName || field.field,
      }));
  }, [editableFields, enabledFields]);

  function toggleModal(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowModal((prev) => !prev);
  }

  const handleFieldSelect = (e) => {
    const fieldName = e.target.value;
    if (fieldName) {
      onCheckToEnable({ target: { checked: true } }, fieldName, item[fieldName]);
    }
  };

  function handleDeleteOverrideToggle(field) {
    if (deleteOverrideFields.has(field.field)) {
      onChange(item, { field: field.field, delete_overlay_mod: false });
    } else {
      onChange(item, { field: field.field, delete_overlay_mod: true });
    }
  }

  // Separate remove handler
  const handleRemoveModification = (e, fieldName) => {
    e.preventDefault(); // Prevent form submission

    onCheckToEnable({ target: { checked: false } }, fieldName, item[fieldName]);
  };

  return (
    <div className="h-full pl-1">
      {tabs.length > 0 && (
        <MenuBar
          className="sticky top-0"
          buttons={buttonsMap}
          tabs={tabs}
          setActiveTab={setEditorTab}
          activeTab={editorTab}
        />
      )}

      <form className={`relative p-2 ${formClassName}`}>
        <div className="h-full">
          {item && (
            <div className="flex flex-col h-full">
              {/* Metadata Fields */}
              {metadataFields.map((field) => (
                <div key={Object.entries(field).join()}>
                  <FormField
                    field={field}
                    rowKey={field.field}
                    fieldGroup={{ ...item }}
                    onChange={onChange}
                    originalValue={item}
                    description={
                      definitions?.schemas?.[editorConfig?.schemas?.GET ?? config.schema]?.[field.field]?.description
                    }
                    disabled={true}
                    pageData={pageData[tab]}
                    sandboxId={item?.original_sandbox_id ?? item?.sandbox_id ?? sandboxId}
                  />
                </div>
              ))}
              <div className="container-query-formfield">
                {editPermitted && selectableFields.length > 0 && (
                  <div className="my-4 cq-base-child">
                    <BasicSelect
                      // label="Select Properties to Override"
                      value=""
                      onChange={handleFieldSelect}
                      options={selectableFields}
                      placeholder="Select Property to Override"
                    />
                  </div>
                )}

                <AnimatePresence mode="popLayout">
                  {editableFields
                    .filter((field) => enabledFields.has(field.field))
                    .map((field) => (
                      <motion.div
                        key={field.field}
                        className="mb-6 cq-base-child"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className="relative bg-zinc-700/20 border border-zinc-600 rounded-[4px] p-4 pb-0">
                          <div className="flex justify-between items-center mb-3 border-b border-zinc-600/50 pb-2">
                            <p
                              className={`text-zinc-300 ${tableSize === "text-xs" ? "text-sm" : "text-base"} ${
                                deleteOverrideFields.has(field.field) ? "line-through" : ""
                              }`}
                            >
                              {field?.headerName}
                            </p>
                            {editPermitted && (
                              <button
                                onClick={(e) => handleRemoveModification(e, field.field)}
                                className="text-rose-300 hover:text-rose-200 transition-colors duration-200 ease-in-out"
                                title="Remove override"
                                type="button"
                              >
                                <CloseIcon className="w-4 h-4" />
                              </button>
                            )}
                          </div>
                          <div className="flex justify-between items-center pb-2">
                            <FormField
                              field={field}
                              rowKey={field.field}
                              fieldGroup={{ ...item }}
                              onChange={onChange}
                              originalValue={item}
                              description={
                                definitions?.schemas?.[editorConfig?.schemas?.GET ?? config.schema]?.[field.field]
                                  ?.description
                              }
                              disabled={
                                !editPermitted ||
                                !enabledFields.has(field.field) ||
                                deleteOverrideFields.has(field.field)
                              }
                              hideLabel={true}
                              pageData={pageData[tab]}
                              sandboxId={item?.original_sandbox_id ?? item?.sandbox_id ?? sandboxId}
                            />
                            {rowOverrides?.length > 0 && rowOverrides.some((mod) => mod.field_name === field.field) && (
                              <label className="flex items-center gap-2 pl-4 mb-3 whitespace-nowrap">
                                <span className="text-xs text-zinc-300">Remove Override</span>
                                <div className="relative inline-flex items-center">
                                  <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    onChange={() => handleDeleteOverrideToggle(field)}
                                    checked={deleteOverrideFields.has(field.field)}
                                    disabled={!editPermitted}
                                  />
                                  <div className="w-8 h-4 bg-zinc-700 rounded-full peer peer-focus:ring-violet-600 peer-checked:bg-violet-600 peer-checked:after:translate-x-[16px] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-3 after:w-3 after:transition-all" />
                                </div>
                              </label>
                            )}
                          </div>
                        </div>
                      </motion.div>
                    ))}
                </AnimatePresence>
              </div>
              {editorTab === "Info" && editPermitted && (
                <div className="text-right mt-auto mb-24">
                  <button
                    onClick={toggleModal}
                    className={`text-white font-medium rounded-sm text-center inline-flex items-center z-10 px-3 py-1 gap-2 transition-colors duration-200 ease-in-out bg-rose-800 hover:bg-pink-950 ${tableSize}`}
                    title="Delete"
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
      {showModal &&
        createPortal(
          <Modal
            isOpen={showModal}
            onClose={toggleModal}
            onConfirm={(e) => onDelete(e)}
            title={"Are you sure?"}
            message={`You are about to delete modifications for ${item?.name ?? item?.original?.name ?? item?.key ?? item?.original?.key ?? item?.original?.loot_id ?? item?.loot_id}.`}
          />,
          document.body,
        )}
    </div>
  );
}
