import { useState } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "contexts/AuthContext";
import { apiRequest } from "common/apiUtils";
import { CloseIcon } from "assets/svgs";
import DataTable from "components/common/datatable";
import dayjs from "dayjs";

export default function CopyScheduleModal({
  isOpen,
  onClose,
  productId,
  currentEnvironmentId,
  scheduleId,
  onSuccess,
  onError,
}) {
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [step, setStep] = useState(1);
  const [rowSelectionModel, setRowSelectionModel] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    name: true,
    description: true,
  });

  const { getAccessToken } = useAuth();

  const environmentsQuery = useQuery({
    queryKey: ["environments", productId, currentEnvironmentId],
    queryFn: async () => {
      const response = await apiRequest(
        await getAccessToken(),
        {
          path: "/v1/product/{product_id}/environment",
          method: "GET",
        },
        {
          product_id: productId,
        },
      );
      return (response || []).filter((env) => env.environment_id !== currentEnvironmentId);
    },
    enabled: Boolean(isOpen && productId && currentEnvironmentId),
  });

  const handleCopy = async (sync) => {
    if (!selectedEnvironment?.environment_id) return;

    try {
      await apiRequest(
        await getAccessToken(),
        {
          path: "/v1/product/{product_id}/environment/{environment_id}/schedule/{schedule_id}/copy",
          method: "POST",
        },
        {
          product_id: productId,
          environment_id: selectedEnvironment.environment_id,
          schedule_id: scheduleId,
        },
        null,
        {},
        {
          queryParams: `sync=${sync}`,
        },
      );

      onSuccess?.(
        sync
          ? "Schedule synced successfully! Changes to the source will be reflected here."
          : "Schedule copied successfully! This is now an independent schedule.",
      );
      onClose();
    } catch (error) {
      console.error("Error copying schedule:", error);
      onError?.(error.message || "Failed to copy schedule");
    }
  };

  const environmentColumns = [
    {
      accessorKey: "name",
      header: "Environment Name",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
  ];

  if (!isOpen) return null;

  return createPortal(
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/80 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={(e) => {
          if (e.target === e.currentTarget) onClose();
        }}
      >
        <motion.div
          className="bg-zinc-900 border border-white/10 rounded-sm w-[900px] max-h-[60vh] p-6 flex flex-col"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
        >
          <div className="flex justify-between items-start mb-6">
            <div>
              <h2 className="text-xl font-bold text-white">Copy Schedule To Environment</h2>
              <div className="flex items-center gap-3 mt-3">
                <div className="flex items-center gap-2">
                  <div
                    className={`w-2.5 h-2.5 rounded-full ${step === 1 ? "bg-violet-500" : "bg-white/20"} transition-colors duration-200`}
                  ></div>
                  <span className={`text-sm ${step === 1 ? "text-white" : "text-white/50"}`}>Select Environment</span>
                </div>
                <div className="w-8 h-[1px] bg-white/10"></div>
                <div className="flex items-center gap-2">
                  <div
                    className={`w-2.5 h-2.5 rounded-full ${step === 2 ? "bg-violet-500" : "bg-white/20"} transition-colors duration-200`}
                  ></div>
                  <span className={`text-sm ${step === 2 ? "text-white" : "text-white/50"}`}>Choose Copy Type</span>
                </div>
              </div>
            </div>
            <button onClick={onClose} className="text-white/70 hover:text-white">
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>

          {step === 1 ? (
            <div className="flex-1 overflow-hidden">
              {environmentsQuery.isPending ? (
                <div className="flex items-center justify-center h-full">
                  <span className="text-white">Loading environments...</span>
                </div>
              ) : (
                <div className="h-[calc(45vh-120px)] overflow-auto">
                  <DataTable
                    data={environmentsQuery.data}
                    columns={environmentColumns}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    columnsWithSort={["name", "description"]}
                    setSelectedRow={(row) => {
                      const environmentData = row?.original || row;
                      setSelectedEnvironment(environmentData);
                    }}
                    selectedRow={selectedEnvironment}
                    setRowSelectionModel={setRowSelectionModel}
                    rowSelectionModel={rowSelectionModel}
                    defaultSortKey="name"
                    rowId="environment_id"
                    className="h-full"
                    readPermitted={true}
                    editPermitted={false}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex-1 overflow-y-auto">
              <div className="text-white mb-6">
                <div className="bg-zinc-800/50 p-4 rounded-md border border-white/5">
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <p className="text-white/50 text-sm mb-1">Environment Name</p>
                      <p className="text-white font-medium">{selectedEnvironment?.name}</p>
                    </div>
                    <div>
                      <p className="text-white/50 text-sm mb-1">Description</p>
                      <p className="text-white font-medium">{selectedEnvironment?.description}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-3">
                <div
                  className="bg-zinc-800/80 p-5 rounded-md border border-white/5 hover:bg-zinc-700/80 cursor-pointer transition-all duration-200 hover:border-emerald-500/30"
                  onClick={() => handleCopy(true)}
                >
                  <div className="flex items-center mb-1.5">
                    <div className="w-2 h-2 rounded-full bg-emerald-500 mr-2"></div>
                    <h4 className="text-white font-semibold">Sync Copy</h4>
                  </div>
                  <p className="text-white/70 text-sm leading-relaxed pl-4">
                    Create a synchronized copy that will stay updated with the source schedule.
                  </p>
                </div>

                <div
                  className="bg-zinc-800/80 p-5 rounded-md border border-white/5 hover:bg-zinc-700/80 cursor-pointer transition-all duration-200 hover:border-sky-500/30"
                  onClick={() => handleCopy(false)}
                >
                  <div className="flex items-center mb-1.5">
                    <div className="w-2 h-2 rounded-full bg-sky-500 mr-2"></div>
                    <h4 className="text-white font-semibold">Full Copy</h4>
                  </div>
                  <p className="text-white/70 text-sm leading-relaxed pl-4">
                    Create an independent copy with a new schedule ID.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end gap-3 mt-4">
            {step === 2 && (
              <button
                onClick={() => setStep(1)}
                className="px-4 py-2 rounded text-white/70 hover:text-white border border-white/10 hover:border-white/30 transition-colors"
              >
                Back
              </button>
            )}
            <button
              onClick={onClose}
              className="px-4 py-2 rounded text-white/70 hover:text-white border border-white/10 hover:border-white/30 transition-colors"
            >
              Cancel
            </button>
            {step === 1 && (
              <button
                onClick={() => setStep(2)}
                disabled={!selectedEnvironment}
                className={`px-4 py-2 rounded bg-violet-600 text-white font-medium transition-colors
                  ${!selectedEnvironment ? "opacity-50 cursor-not-allowed" : "hover:bg-violet-500"}`}
              >
                Next
                {!selectedEnvironment && " (Select an environment)"}
              </button>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>,
    document.body,
  );
}
