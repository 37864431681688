import { useState, useEffect, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import DataTableRow from "./components/DataTableRow";
import DataTableHeader from "./components/DataTableHeader";
import DataTableToolbar from "./components/DataTableToolbar";
import { useLayout } from "contexts/LayoutContext";

export default function DataTable({
  data,
  columns,
  selectedRow,
  setSelectedRow,
  setRowSelectionModel,
  rowSelectionModel,
  defaultSortKey,
  columnVisibility,
  setColumnVisibility,
  columnsWithSort = [],
  handleAdd,
  rowId,
  enableMultiRowSelection = false,
  hideToolbar = false,
  readPermitted = false,
  editPermitted = false,
  className,
  isModal = false,
}) {
  const buttonsMap = editPermitted && handleAdd ? [{ name: "Add", fn: () => handleAdd() }] : [];

  const { tableSize } = useLayout();

  const [sorting, setSorting] = useState(defaultSortKey ? [{ id: defaultSortKey, desc: false }] : []);
  const [globalFilter, setGlobalFilter] = useState("");

  // https://tanstack.com/table/latest/docs/framework/react/examples/filters-fuzzy
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
      itemRank,
    });

    return itemRank.passed;
  };

  // To prevent a blink of empty rows when tab is switched
  const dataMemo = useMemo(() => {
    return data.some((row) => row.hasOwnProperty(rowId)) ? data : [];
  }, [rowId, data]);

  useEffect(() => {
    if (columns && sorting.length > 0) {
      const validSortColumns = columns.map((col) => col.id);
      const hasInvalidSort = sorting.some((sort) => !validSortColumns.includes(sort.id));
      if (hasInvalidSort) {
        setSorting(defaultSortKey ? [{ id: defaultSortKey, desc: false }] : []);
      }
    }
  }, [columns, defaultSortKey, rowId, data]);

  // Initialize the table regardless of data and columns' states; they will be updated accordingly
  const table = useReactTable({
    data: dataMemo,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
      rowSelection: rowSelectionModel,
      columnVisibility,
    },
    // If there is a sandbox_id, add it to the rowId to create a unique pk. This is used for KVs
    getRowId: (row) => `${row[rowId]}${row?.sandbox_id ? `-${row?.sandbox_id}` : ""}`,
    // manualSorting: true,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelectionModel,
    enableMultiRowSelection: enableMultiRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    // onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: "auto",
    defaultColumn: {
      maxSize: 800,
    },
  });

  const onRowClick = (row) => {
    setSelectedRow(row);
  };

  const selectedRowData = useMemo(() => {
    const selectedRows = table.getSelectedRowModel().rows;
    return selectedRows.length > 0 ? selectedRows[0] : null;
  }, [table.getState().rowSelection, selectedRow]);

  useEffect(() => {
    if (rowSelectionModel && !selectedRow?.original) {
      if (selectedRowData) {
        setSelectedRow(selectedRowData);
      } else {
        setSelectedRow();
      }
    }
  }, [data]);

  return (
    <div
      className={`text-white/90 fill-current table-container max-w-full h-full overflow-y-auto ${className} ${isModal ? "max-h-[65vh] relative" : "h-[calc(100vh-50px)]"}`}
    >
      {!hideToolbar && (
        <DataTableToolbar
          table={table}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          buttons={buttonsMap}
        />
      )}
      <div className={`border-white/5 relative`}>
        <table className={`min-w-full w-full text-left ${tableSize}`}>
          <DataTableHeader
            table={table}
            sorting={sorting}
            setSorting={setSorting}
            columnsWithSort={columnsWithSort}
            enableMultiRowSelection={enableMultiRowSelection}
          />
          {data.length > 0 && columns ? (
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <DataTableRow
                  key={row.id}
                  row={row}
                  isSelected={Object.keys(rowSelectionModel).includes(row.id)}
                  onClick={() => onRowClick(row)}
                  enableMultiRowSelection={enableMultiRowSelection}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={columns.length} className="text-center">
                  <p className="text-white/90 text-center mt-5 text-base font-roboto w-full">No Data</p>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <div className="flex items-center justify-end mt-2 gap-2"></div>
    </div>
  );
}
