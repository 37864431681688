import { useState, useEffect, Fragment, useCallback } from "react";
import FieldWrapper from "./FormField/FieldWrapper";
import TextField from "./FormField/TextField";
import { PlusIcon, MinusIcon, ExpandIcon, CollapseIcon } from "assets/svgs";
import { motion, AnimatePresence } from "framer-motion";
import FlashText from "./FormField/FlashText";

export default function CustomDataField({
  label,
  name,
  value,
  disabled,
  onChange,
  required,
  description,
  setEditedDetails,
  rowKey,
  resetFlag,
}) {
  const [prevRowKey, setPrevRowKey] = useState(rowKey);
  const [localSelectedItem, setLocalSelectedItem] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [showFlashText, setShowFlashText] = useState(false);

  const generateId = () => `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

  function addRow() {
    setLocalSelectedItem((prev) => [...prev, { id: generateId(), values: ["", ""] }]);
  }

  function deleteRow(rowId) {
    if (localSelectedItem.length > 1) {
      setLocalSelectedItem((prev) => prev.filter((item) => item.id !== rowId));
    } else {
      setLocalSelectedItem([{ id: generateId(), values: ["", ""] }]);
    }
  }

  function initializeLocalSelectedItem() {
    if (!value || Object.keys(value).length === 0) {
      setLocalSelectedItem([{ id: generateId(), values: ["", ""] }]);
    } else {
      setLocalSelectedItem(
        Object.entries(value).map((entry) => ({
          id: generateId(),
          values: entry,
        })),
      );
    }
  }

  function isEmptyObject(arrayEntries) {
    return arrayEntries.length === 0 || (arrayEntries.length === 1 && arrayEntries[0].values[0] === "");
  }

  function toggleValueResize(e, rowId) {
    e.preventDefault();
    e.stopPropagation();
    const parentDiv = e.currentTarget.parentElement;
    const textField = parentDiv.querySelector("textarea, input");

    if (textField) {
      setExpandedRows((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(rowId)) {
          textField.style.height = "0";
          newSet.delete(rowId);
        } else {
          const scrollHeight = textField.scrollHeight;
          textField.style.height = "0";
          textField.offsetHeight;
          textField.style.height = `${scrollHeight}px`;
          newSet.add(rowId);
        }
        return newSet;
      });
    }
  }

  function copyTextToClipboard(e) {
    e.preventDefault();
    e.stopPropagation();
    const inputElement = e.currentTarget.querySelector("input, textarea");
    if (inputElement) {
      navigator.clipboard.writeText(inputElement.value);
      setShowFlashText(true);
    }
  }

  const handleChange = useCallback((e, rowId, valueIndex) => {
    setLocalSelectedItem((prevItems) => {
      return prevItems.map((item) =>
        item.id === rowId
          ? {
              ...item,
              values: [...item.values.slice(0, valueIndex), e.target.value, ...item.values.slice(valueIndex + 1)],
            }
          : item,
      );
    });
  }, []);

  useEffect(() => {
    const constructedEvent = {
      target: {
        name: "custom_data",
        value: isEmptyObject(localSelectedItem) ? {} : Object.fromEntries(localSelectedItem.map((item) => item.values)),
        type: "json",
      },
    };
    setEditedDetails(constructedEvent);
  }, [localSelectedItem]);

  useEffect(() => {
    if (prevRowKey !== rowKey) {
      setPrevRowKey(rowKey);
      initializeLocalSelectedItem();
    }
  }, [rowKey]);

  useEffect(() => {
    initializeLocalSelectedItem();
  }, [resetFlag]);

  const handleFlashTextClose = () => {
    setShowFlashText(false);
  };

  return (
    <div className="relative">
      <FieldWrapper label={label} inactive={disabled} description={description} fullWidth={true}>
        <form>
          {localSelectedItem &&
            localSelectedItem.map((row, i) => (
              <Fragment key={row.id}>
                <div className="flex justify-start flex-wrap items-start">
                  <AnimatePresence>
                    {row.values &&
                      row.values.map((val, j) => (
                        <motion.div
                          className={`flex p-2 min-w-[350px] ${j === 0 ? "" : "flex-1"} ${disabled ? "cursor-copy" : ""}`}
                          key={row.id + "-" + j}
                          onClick={disabled ? copyTextToClipboard : null}
                          initial={{ opacity: 0, height: 0, y: -10 }}
                          animate={{
                            opacity: 1,
                            height: "auto",
                            y: 0,
                            transition: {
                              height: { duration: 0.1 },
                              opacity: { duration: 0.1 },
                              y: { duration: 0.1 },
                            },
                          }}
                          exit={{
                            opacity: 0,
                            height: 0,
                            y: -10,
                            transition: {
                              height: { duration: 0.1 },
                              opacity: { duration: 0.1 },
                              y: { duration: 0.1 },
                            },
                          }}
                        >
                          <div className={`flex relative items-center ${j === 0 ? "w-[350px]" : "w-full"}`}>
                            <TextField
                              type={j === 0 ? "text" : "textarea"}
                              description={j === 0 ? "Key" : "Value"}
                              name={j === 0 ? "Key" : "Value"}
                              label={j === 0 ? "Key" : "Value"}
                              value={val}
                              onChange={(e) => handleChange(e, row.id, j)}
                              className={`transition-all duration-300 ${disabled ? "pointer-events-none opacity-70" : ""}`}
                            />
                            {j !== 0 && (
                              <button
                                onClick={(e) => toggleValueResize(e, row.id)}
                                className={`bg-white/10 rounded-full cursor-pointer h-7 w-7 flex items-center justify-center ${disabled ? "opacity-70 pointer-events-none" : ""}`}
                                style={{ marginLeft: "0.5rem" }}
                              >
                                {expandedRows.has(row.id) ? <CollapseIcon /> : <ExpandIcon />}
                              </button>
                            )}
                          </div>
                        </motion.div>
                      ))}
                  </AnimatePresence>
                  <div
                    className={`flex items-center self-stretch ml-4 ${disabled ? "pointer-events-none opacity-30" : ""}`}
                  >
                    <div
                      className="flex items-center justify-center w-6 h-6 bg-white/10 rounded-full cursor-pointer mr-2 "
                      onClick={() => deleteRow(row.id)}
                    >
                      <MinusIcon />
                    </div>
                    {i === localSelectedItem.length - 1 &&
                      localSelectedItem[localSelectedItem.length - 1].values[0] &&
                      localSelectedItem[localSelectedItem.length - 1].values[1] && (
                        <div
                          className="flex items-center justify-center w-6 h-6 bg-white/10 rounded-full cursor-pointer"
                          onClick={addRow}
                        >
                          <PlusIcon />
                        </div>
                      )}
                  </div>
                </div>
                {localSelectedItem.length > 1 && i !== localSelectedItem.length - 1 && (
                  <div className="px-3 pt-2 pb-3 opacity-30">
                    <hr />
                  </div>
                )}
              </Fragment>
            ))}
        </form>
      </FieldWrapper>
      <FlashText
        open={showFlashText}
        onClose={handleFlashTextClose}
        message="Value copied to clipboard!"
        autoHideDuration={1500}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
}
