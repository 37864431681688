import { useState, useEffect, useMemo, useReducer, useContext, useRef, Fragment } from "react";
import { useQuery, useQueryClient, keepPreviousData } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import { useLayout } from "contexts/LayoutContext";
import RightDrawer from "components/common/RightDrawer";
import { apiRequest, usePortalQuery, usePortalMutation, getErrorMessage } from "common/apiUtils";
import LoadingMask from "components/common/DynamicLoadingMask";
import MenuBar from "components/common/MenuBar";
import DropdownMenu from "components/common/DropdownMenu";
import { OpenDrawerIcon } from "assets/svgs";
import SearchAndSelect from "components/common/FormField/SearchAndSelect";
import BasicSelect from "components/common/FormField/BasicSelect";
import DataTable from "components/common/datatable";
import OverridesForm from "components/common/Form/OverridesForm";
import {
  overridesCreateReducer,
  overridesPutReducer,
  overridesDeleteReducer,
  initialState,
} from "components/common/Form/overridesReducer";

import definitions from "common/definitions.json";
import CONFIG from "./overrides.config";

export default function PublishingOverrides({ scheduleId, className, setFormIsDirty }) {
  const modsQueryPath = useMemo(() => {
    if (scheduleId) {
      return `/v1/product/{product_id}/schedule/{schedule_id}/modification`;
    }
    return `/v1/product/{product_id}/overlay/{overlay_id}/modification`;
  }, [scheduleId]);

  const modsDeletePath = useMemo(() => {
    if (scheduleId) {
      return `/v1/product/{product_id}/schedule/{schedule_id}/modification`;
    }
    return `/v1/product/{product_id}/overlay/{overlay_id}/modification`;
  }, [scheduleId]);

  const queryClient = useQueryClient();
  const { tableSize } = useLayout();

  const tabs = Object.keys(CONFIG.tabs);
  const defaultTab = tabs.find((key) => CONFIG.tabs[key]?.default) ?? tabs[0];

  const { defaultDrawerWidth } = useLayout();
  const { currentStateData, permissionData, hasPermission } = useContext(AccountContext);
  const { getAccessToken } = useAuth();

  const columnHelper = createColumnHelper();

  let isResizing = useRef(false);

  const [selectedRow, setSelectedRow] = useState();
  const [selectedSandbox, setSelectedSandbox] = useState();
  const [rowSelectionModel, setRowSelectionModel] = useState({});
  const [activeTab, setActiveTab] = useState(defaultTab);

  function convertToString(value) {
    try {
      return value?.toString();
    } catch (error) {
      return value;
    }
  }

  const columns = useMemo(
    () =>
      CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.map((field, i) => {
        return field.field === "name" || field.field === "key"
          ? columnHelper.accessor(field.field, {
              cell: (info) => convertToString(info.getValue()),
              header: () => <span>{field.headerName}</span>,
              minSize: 250,
              maxSize: 500,
            })
          : columnHelper.accessor(field.field, {
              cell: (info) => convertToString(info.getValue()),
              header: () => <span>{field.headerName}</span>,
              size: Number.MAX_SAFE_INTEGER,
            });
      }),
    [activeTab],
  );

  const defaultColumnVisibility = useMemo(
    () =>
      CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.reduce((acc, field) => {
        acc[field.field] = field.tableVisibility ?? true;
        return acc;
      }, {}),
    [activeTab],
  );

  const ROW_ID = useMemo(() => CONFIG.tabs[activeTab]?.rowId, [activeTab]);
  const TARGET_TYPE = useMemo(() => CONFIG.tabs[activeTab]?.targetType, [activeTab]);
  const PLURAL_ROW_ID = useMemo(() => CONFIG.tabs[activeTab]?.pluralRowId, [activeTab]);

  const [drawerLoadingMessage, setDrawerLoadingMessage] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(0);

  const [searchAndSelectValue, setSearchAndSelectValue] = useState();
  const [defaultTableData, setDefaultTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [error, setError] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const mutationStates = useRef({
    create: null,
    update: null,
    delete: null,
  });

  const [saving, setSaving] = useState(false);

  const [createState, createDispatch] = useReducer(overridesCreateReducer, initialState);
  const [updatesState, updatesDispatch] = useReducer(overridesPutReducer, initialState);
  const [deleteState, deleteDispatch] = useReducer(overridesDeleteReducer, initialState);

  const isDirty = useMemo(() => {
    return createState.length || updatesState.length || deleteState.length;
  }, [createState, updatesState, deleteState]);

  const [enabledFields, setEnabledFields] = useState(new Set());
  const [deleteOverrideFields, setDeleteOverrideFields] = useState(new Set());
  const [showModal, setShowModal] = useState(false);

  const drawerButtonsMap = [
    {
      name: "Close Drawer",
      icon: <OpenDrawerIcon className="rotate-180" />,
      fn: setDrawerWidth,
      args: [0],
    },
  ];

  const buttonsMap = [
    {
      name: "Table Size",
      icon: <DropdownMenu type="TableSize" />,
    },
    // {
    //   name: "Open Drawer",
    //   icon: <OpenDrawerIcon className="" />,
    //   fn: setDrawerWidth,
    //   args: [350],
    // },
  ];

  const valueKeys = useMemo(
    () =>
      CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.reduce((acc, field) => {
        switch (field.type) {
          case "boolean":
            acc[field.field] = "value_boolean";
            break;
          case "singleDynamicSelect":
            acc[field.field] = "value_string";
            break;
          case "datetime":
            acc[field.field] = "value_datetime";
            break;
          default:
            acc[field.field] = "value_string";
            break;
        }
        return acc;
      }, {}),
    [activeTab],
  );

  const readPermitted =
    permissionData && currentStateData?.environment?.environmentId
      ? hasPermission(
          "environment:config:view",
          "environment",
          currentStateData?.environment?.orgId,
          currentStateData,
          permissionData,
        )
      : false;

  const editPermitted =
    permissionData && currentStateData?.environment?.environmentId
      ? hasPermission(
          "environment:config:edit",
          "environment",
          currentStateData?.environment?.environmentId,
          currentStateData,
          permissionData,
        ) ||
        hasPermission(
          "environmentOrg:config:edit",
          "environment",
          currentStateData?.environment?.environmentId,
          currentStateData,
          permissionData,
        )
      : false;

  async function enableOverlay() {
    const overlayResponse = await apiRequest(
      getAccessToken(),
      {
        path: "/v1/product/{product_id}/overlay",
        method: "POST",
      },
      {
        product_id: currentStateData?.product?.productId,
      },
      {
        name: `Default Publishing Overlay for product ${currentStateData?.product?.productId}, environment ${currentStateData?.environment?.environmentId}`,
      },
    );

    const environmentOverlayResponse = await apiRequest(
      getAccessToken(),
      {
        path: "/v1/product/{product_id}/environment/{environment_id}/overlay",
        method: "POST",
      },
      {
        product_id: currentStateData?.product?.productId,
        environment_id: currentStateData?.environment?.environmentId,
      },
      {
        overlay_id: overlayResponse?.overlay_id,
      },
    );

    if (environmentOverlayResponse?.overlay_id) {
      queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === "overlays" && query.queryKey[1] === currentStateData?.product?.productId) ||
          (query.queryKey[0] === "mods" && query.queryKey[1] === overlaysQuery.data?.[0]?.overlay_id),
      });
    }
  }

  const environmentQuery = useQuery({
    queryKey: ["environment", currentStateData?.environment?.environmentId],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: `/v1/product/{product_id}/environment/{environment_id}`, method: "GET" },
        {
          product_id: currentStateData?.product?.productId,
          environment_id: currentStateData?.environment?.environmentId,
        },
      ),
    enabled: !!currentStateData?.product?.productId && !!currentStateData?.environment?.environmentId,
  });

  const currentSandboxQuery = useQuery({
    queryKey: ["sandbox", environmentQuery.data?.base_sandbox_id],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        {
          path: `/v1/org/{org_identifier}/product/{product_identifier}/sandbox/{sandbox_id}`,
          method: "GET",
        },
        {
          org_identifier: currentStateData?.org?.orgId,
          product_identifier: currentStateData?.product?.productId,
          sandbox_id: environmentQuery.data?.base_sandbox_id,
        },
      ),
    enabled:
      !!currentStateData?.org?.orgId &&
      !!currentStateData?.product?.productId &&
      !!environmentQuery.data?.base_sandbox_id,
  });

  const sandboxesQuery = useQuery({
    queryKey: ["sandboxes"],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        {
          path: `/v1/org/{org_identifier}/product/{product_identifier}/sandboxes`,
          method: "GET",
        },
        {
          org_identifier: currentStateData?.org?.orgId,
          product_identifier: currentStateData?.product?.productId,
        },
      ),
    enabled: !!currentStateData?.org?.orgId && !!currentStateData?.product?.productId,
  });

  const sandboxOptions = useMemo(
    () =>
      sandboxesQuery?.data
        ? sandboxesQuery.data
            .filter((sandbox) => sandbox.archive !== true)
            .map((sandbox) => ({
              id: sandbox.sandbox_id,
              title: sandbox.name,
            }))
        : [],
    [sandboxesQuery?.data],
  );

  const singleSelectQuery = useQuery({
    queryKey: [CONFIG.tabs[activeTab]?.targetType, selectedSandbox, searchAndSelectValue],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        definitions?.schemas[CONFIG.tabs[activeTab]?.schema]?.endpoints.find((endpoint) => endpoint.method === "get"),
        {
          sandbox_id:
            selectedSandbox === environmentQuery.data?.base_sandbox_id && scheduleId
              ? environmentQuery.data?.sandbox_id
              : selectedSandbox,
        },
        null,
        null,
        {
          queryParams: searchAndSelectValue
            ? { page_size: 50, expand: "*", [CONFIG.tabs[activeTab]?.searchKey ?? "name"]: searchAndSelectValue }
            : { page_size: 50, expand: "*" },
        },
      ),
    enabled: !!selectedSandbox,
    placeholderData: keepPreviousData,
  });

  const singleSelectOptions = useMemo(() => {
    return singleSelectQuery?.data?.[CONFIG.tabs[activeTab]?.allResponseKey ?? "data"]?.length > 0
      ? singleSelectQuery?.data?.[CONFIG.tabs[activeTab]?.allResponseKey ?? "data"]?.map((currentData) => ({
          id: currentData?.[ROW_ID],
          title: currentData.name ?? currentData?.[ROW_ID],
        }))
      : Array.isArray(singleSelectQuery?.data) && singleSelectQuery?.data.length > 0
        ? singleSelectQuery?.data?.map((currentData) => ({
            id: currentData?.[ROW_ID],
            title: currentData.name ?? currentData?.[ROW_ID],
          }))
        : [];
  }, [singleSelectQuery.data]);

  const overlaysQuery = useQuery({
    queryKey: ["overlays", currentStateData?.product?.productId],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: "/v1/product/{product_id}/environment/{environment_id}/overlay", method: "GET" },
        {
          product_id: currentStateData?.product?.productId,
          environment_id: currentStateData?.environment?.environmentId,
        },
      ),
    enabled: !!currentStateData?.product?.productId && !!currentStateData?.environment?.environmentId,
  });

  const modsQuery = useQuery({
    queryKey: ["mods", overlaysQuery.data?.[0]?.overlay_id, scheduleId],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: modsQueryPath, method: "GET" },
        {
          product_id: currentStateData?.product?.productId,
          overlay_id: overlaysQuery?.data?.[0]?.overlay_id,
          schedule_id: scheduleId,
        },
        null,
        null,
        { queryParams: { page_size: 5000, expand: "*" } },
      ),
    enabled: !!currentStateData?.product?.productId && !!overlaysQuery.data?.[0]?.overlay_id,
  });

  const overlayModsQueryForScheduler = useQuery({
    queryKey: ["overlayMods", overlaysQuery.data?.[0]?.overlay_id, scheduleId],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: "/v1/product/{product_id}/overlay/{overlay_id}/modification", method: "GET" },
        {
          product_id: currentStateData?.product?.productId,
          overlay_id: overlaysQuery?.data?.[0]?.overlay_id,
        },
        null,
        null,
        { queryParams: { page_size: 5000, expand: "*" } },
      ),
    enabled: !!currentStateData?.product?.productId && !!overlaysQuery.data?.[0]?.overlay_id && !!scheduleId,
  });

  const activeSandboxIds = useMemo(() => {
    return [
      ...new Set(
        modsQuery?.data?.data?.map((mod) => mod?.reference_sandbox_id || environmentQuery.data?.base_sandbox_id),
      ),
    ];
  }, [modsQuery?.data?.data, environmentQuery.data?.base_sandbox_id, environmentQuery.data?.environmentId]);

  const replacements = useMemo(() => {
    return {
      sandbox_id: currentSandboxQuery?.data?.sandbox_id,
      product_id: currentStateData?.product?.productId,
      org_id: currentStateData?.org?.orgId,
      environment_id: currentStateData?.environment?.environmentId,
      overlay_id: overlaysQuery?.data?.[0]?.overlay_id ?? "",
      schedule_id: scheduleId,
    };
  }, [
    currentSandboxQuery?.data?.sandbox_id,
    currentStateData?.product?.productId,
    currentStateData?.org?.orgId,
    overlaysQuery?.data,
    scheduleId,
  ]);

  const updateMutation = usePortalMutation({
    queryClient,
    schema: scheduleId ? "ScheduleModificationUpdate" : "OverlayModificationUpdate",
    token: getAccessToken(),
    replacements,
    method: "put",
    onErrorCallback: (error) => {
      const errorMsg = getErrorMessage(error, CONFIG.fields);
      setError(errorMsg);
    },
  });

  const createMutation = usePortalMutation({
    queryClient,
    schema: scheduleId ? "ScheduleModificationCreate" : "OverlayModificationCreate",
    token: getAccessToken(),
    replacements,
    method: "post",
    onErrorCallback: (error) => {
      console.error("createMutation error", error);
      const errorMsg = getErrorMessage(error, CONFIG.fields);
      setError(errorMsg);
    },
  });

  const deleteMutation = usePortalMutation({
    queryClient,
    schema: scheduleId ? "ScheduleModificationListResponse" : "OverlayModificationListResponse",
    token: getAccessToken(),
    replacements,
    method: "delete",
    onErrorCallback: (error) => {
      console.error("deleteMutation error", error);
      const errorMsg = getErrorMessage(error, CONFIG.fields);
      setError(errorMsg);
    },
  });

  function handleSave() {
    let mutationPromises = [];

    if (createState.length) {
      mutationStates.current.create = "pending";
      mutationPromises.push(
        createMutation
          .mutateAsync({ data: createState })
          .then(() => {
            mutationStates.current.create = "success";
          })
          .catch(() => {
            mutationStates.current.create = "error";
          }),
      );
    }

    if (updatesState.length) {
      mutationStates.current.update = "pending";
      mutationPromises.push(
        updateMutation
          .mutateAsync({ data: updatesState })
          .then(() => {
            mutationStates.current.update = "success";
          })
          .catch(() => {
            mutationStates.current.update = "error";
          }),
      );
    }

    if (deleteState.length) {
      const deleteIds = deleteState.map((mod) => mod.modification_id);
      const deleteIdsParam = deleteIds.map((id) => `modification_ids=${id}`).join("&");

      mutationStates.current.delete = "pending";
      mutationPromises.push(
        deleteMutation
          .mutateAsync({ extraOptionsOverride: { queryParams: deleteIdsParam } })
          .then(() => {
            mutationStates.current.delete = "success";
          })
          .catch(() => {
            mutationStates.current.delete = "error";
          }),
      );
    }
    setSaving(true);

    Promise.all(mutationPromises)
      .then((results) => {
        return results;
      })
      .catch((error) => {
        console.error("handleSave error", error);
        const errorMsg = getErrorMessage(error, CONFIG.fields);
        setError(errorMsg);
      })
      .finally(async () => {
        const isAnyPending = Object.values(mutationStates).some((state) => state === "pending");

        // Delay the success message to allow the Save/Cancel buttons to animate off
        // and Success to animate on
        const timeout1 = setTimeout(() => {
          setShowSuccessMessage(true);
          clearTimeout(timeout1);
        }, 200);
        const timeout2 = setTimeout(() => {
          setShowSuccessMessage(false);
          clearTimeout(timeout2);
        }, 2500);

        if (!isAnyPending) {
          mutationStates.current = {
            create: null,
            update: null,
            delete: null,
          };
          setSaving(false);
          setError();
          deleteDispatch({ type: "RESET_STATE" });
          updatesDispatch({ type: "RESET_STATE" });
          createDispatch({ type: "RESET_STATE" });
          await queryClient.invalidateQueries({ queryKey: ["mods", overlaysQuery.data?.[0]?.overlay_id, scheduleId] });
          if (scheduleId) {
            await queryClient.invalidateQueries({
              queryKey: [
                "ScheduleModificationListResponse",
                {
                  schedule_id: scheduleId,
                  product_id: currentStateData?.product?.productId,
                },
              ],
            });
          }
        }
      });
  }

  async function handleDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    const itemId = selectedRow?.original?.[ROW_ID] ?? selectedRow?.[ROW_ID];
    const filteredCreate = createState.filter((mod) => mod?.target_id !== itemId);
    createDispatch({ type: "UPDATE_STATE", payload: { value: filteredCreate } });

    const filteredUpdates = updatesState.filter((mod) => mod?.target_id !== itemId);
    updatesDispatch({ type: "UPDATE_STATE", payload: { value: filteredUpdates } });

    const modsToRemove = modsQuery.data?.data?.reduce((acc, mod) => {
      if (mod?.target_id === itemId) {
        acc.push(mod.modification_id);
      }
      return acc;
    }, []);

    if (!modsToRemove.length) {
      const filteredTableData = tableData.filter((row) => row?.[ROW_ID] !== itemId);
      setTableData(filteredTableData);

      const filteredDefaultTableData = defaultTableData.filter((row) => row?.[ROW_ID] !== itemId);
      setDefaultTableData(filteredDefaultTableData);

      setSelectedRow();
      setRowSelectionModel({});
      setShowModal(false);

      return;
    }

    const modsToRemoveParam = modsToRemove.map((id) => `modification_ids=${id}`).join("&");

    const response = await apiRequest(
      await getAccessToken(),
      { method: "DELETE", path: modsDeletePath },
      replacements,
      null,
      null,
      { queryParams: modsToRemoveParam },
    );

    if (response.status === 204) {
      queryClient.refetchQueries({ queryKey: ["mods", overlaysQuery.data?.[0]?.overlay_id, scheduleId] });
      setSelectedRow();
      setRowSelectionModel({});
    }
    setShowModal(false);
  }

  function handleCancel() {
    setError();
    setTableData(defaultTableData);
    setSelectedRow();
    setRowSelectionModel({});
    deleteDispatch({ type: "RESET_STATE" });
    updatesDispatch({ type: "RESET_STATE" });
    createDispatch({ type: "RESET_STATE" });
  }

  function handleChange(data, options) {
    const fieldName = options?.field;
    const existingMod = modsQuery?.data?.data?.find(
      (mod) => mod.target_id === data[ROW_ID] && mod.field_name === fieldName,
    );

    if (existingMod) {
      const updateEntry = {
        target_id: data[ROW_ID],
        target_type: TARGET_TYPE,
        field_name: fieldName,
        [valueKeys[fieldName]]: data[fieldName],
        modification_id: existingMod?.modification_id,
        reference_sandbox_id: data?.sandbox_id ?? selectedSandbox ?? environmentQuery.data?.base_sandbox_id,
        delete_overlay_mod: options?.delete_overlay_mod,
      };

      let updates = updatesState.length ? updatesState : [];

      const updateIndex = updates.findIndex((mod) => mod.modification_id === updateEntry.modification_id);
      if (updateIndex > -1) {
        updates.splice(updateIndex, 1, updateEntry);
      } else {
        updates = [...updatesState, updateEntry];
      }

      updatesDispatch({ type: "UPDATE_STATE", payload: { value: updates } });
    } else {
      const newEntry = {
        target_id: data[ROW_ID],
        target_type: TARGET_TYPE,
        field_name: fieldName,
        [valueKeys[fieldName]]: data[fieldName],
        reference_sandbox_id: data?.sandbox_id ?? selectedSandbox ?? environmentQuery.data?.base_sandbox_id,
        delete_overlay_mod: options?.delete_overlay_mod,
      };

      if (!createState.length) {
        createDispatch({ type: "UPDATE_STATE", payload: { value: [newEntry] } });
      } else {
        const existingCreate = createState.find(
          (mod) => mod.target_id === data[ROW_ID] && mod.field_name === fieldName,
        );

        if (existingCreate) {
          createDispatch({
            type: "UPDATE_STATE",
            payload: {
              value: createState.map((mod) =>
                mod.target_id === data[ROW_ID] && mod.field_name === fieldName ? newEntry : mod,
              ),
            },
          });
        } else {
          createDispatch({ type: "UPDATE_STATE", payload: { value: [...createState, newEntry] } });
        }
      }
    }

    const updatedRow = {
      ...(selectedRow?.original ? selectedRow.original : selectedRow),
      [fieldName]: data[fieldName],
    };

    processSelectedRow(updatedRow);
  }

  function addToTable(dataOption) {
    let fullObject;
    if (singleSelectQuery?.data?.[CONFIG.tabs[activeTab]?.allResponseKey ?? "data"]) {
      fullObject = singleSelectQuery?.data?.[CONFIG.tabs[activeTab]?.allResponseKey ?? "data"].find(
        (x) => x?.[ROW_ID] === dataOption.id,
      );
    } else if (Array.isArray(singleSelectQuery?.data)) {
      fullObject = singleSelectQuery?.data.find((x) => x?.[ROW_ID] === dataOption.id);
    }
    if (fullObject) {
      fullObject.sandbox_name = sandboxesQuery?.data?.find(
        (sandbox) => sandbox.sandbox_id === fullObject.sandbox_id,
      )?.name;
      fullObject.sandbox_id = fullObject.sandbox_id ?? selectedSandbox;
    } else {
      fullObject = {
        [ROW_ID]: dataOption.id,
        sandbox_name: sandboxesQuery?.data?.find((sandbox) => sandbox.sandbox_id === selectedSandbox)?.name,
        sandbox_id: selectedSandbox,
      };
    }

    setTableData((prev) => [...prev, fullObject]);
    // This pk for the table is established in the DataTable and we have to match it here.
    // Because we can have multiple rows with the same target_id across sandboxes, I include the sandbox_id in the pk to make it unique
    setRowSelectionModel({ [`${fullObject?.[ROW_ID]}-${fullObject?.sandbox_id}`]: true });
    processSelectedRow(fullObject);
  }

  function onCheckToEnable(e, field, value) {
    const selectedRowId = selectedRow?.[ROW_ID] ?? selectedRow?.original?.[ROW_ID];

    if (e.target.checked) {
      setEnabledFields((prev) => new Set([...prev, field]));

      const existingCreateIndex = createState.findIndex(
        (mod) => mod.field_name === field && mod.target_id === selectedRowId && mod.target_type === TARGET_TYPE,
      );

      if (existingCreateIndex === -1) {
        createDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: [
              ...createState,
              {
                target_id: selectedRowId,
                target_type: TARGET_TYPE,
                field_name: field,
                [valueKeys[field]]: value,
                reference_sandbox_id:
                  selectedRow?.original?.sandbox_id ?? selectedSandbox ?? environmentQuery.data?.base_sandbox_id,
              },
            ],
          },
        });
      }

      if (deleteState.some((mod) => mod.field_name === field && mod.target_id === selectedRowId)) {
        deleteDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: deleteState.filter((mod) => !(mod.field_name === field && mod.target_id === selectedRowId)),
          },
        });
      }
    } else {
      setEnabledFields((prev) => new Set([...prev].filter((f) => f !== field)));

      if (updatesState.some((mod) => mod.field_name === field && mod.target_id === selectedRowId)) {
        updatesDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: updatesState.filter((mod) => !(mod.field_name === field && mod.target_id === selectedRowId)),
          },
        });
      }

      if (createState.some((mod) => mod.field_name === field && mod.target_id === selectedRowId)) {
        createDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: createState.filter((mod) => !(mod.field_name === field && mod.target_id === selectedRowId)),
          },
        });
      }

      const existingMod = modsQuery.data?.data?.find(
        (mod) => mod.field_name === field && mod.target_id === selectedRowId,
      );

      if (existingMod) {
        deleteDispatch({
          type: "UPDATE_STATE",
          payload: {
            value: [...deleteState, existingMod],
          },
        });
      }
    }
  }

  const rowOverrides = useMemo(() => {
    const rowData = selectedRow?.original ?? selectedRow;
    return overlayModsQueryForScheduler.data?.data?.filter(
      (mod) => mod.target_id === rowData?.[ROW_ID] && mod.target_type === CONFIG.tabs[activeTab]?.targetType,
    );
  }, [selectedRow, overlayModsQueryForScheduler]);

  function processSelectedRow(row) {
    const enabledFieldsForRow = new Set();
    const deleteOverrideFieldsForRow = new Set();
    const rowData = row?.original ?? row;

    modsQuery.data?.data?.forEach((mod) => {
      if (mod?.target_id === rowData?.[ROW_ID] && mod?.target_type === CONFIG.tabs[activeTab]?.targetType) {
        enabledFieldsForRow.add(mod.field_name);
        if (mod.delete_overlay_mod) {
          deleteOverrideFieldsForRow.add(mod.field_name);
        }
      }
    });

    updatesState.forEach((mod) => {
      if (mod?.target_id === rowData?.[ROW_ID] && mod?.target_type === CONFIG.tabs[activeTab]?.targetType) {
        enabledFieldsForRow.add(mod.field_name);
        if (mod.delete_overlay_mod) {
          deleteOverrideFieldsForRow.add(mod.field_name);
        }
      }
    });

    createState.forEach((mod) => {
      if (mod?.target_id === rowData?.[ROW_ID] && mod?.target_type === CONFIG.tabs[activeTab]?.targetType) {
        enabledFieldsForRow.add(mod.field_name);
        if (mod.delete_overlay_mod) {
          deleteOverrideFieldsForRow.add(mod.field_name);
        }
      }
    });

    deleteState.forEach((mod) => {
      if (mod?.target_id === rowData?.[ROW_ID] && mod?.target_type === CONFIG.tabs[activeTab]?.targetType) {
        enabledFieldsForRow.delete(mod.field_name);
        if (mod.delete_overlay_mod) {
          deleteOverrideFieldsForRow.delete(mod.field_name);
        }
      }
    });
    setEnabledFields(enabledFieldsForRow);
    setDeleteOverrideFields(deleteOverrideFieldsForRow);
    setSelectedRow(rowData);
  }

  function appendUnsavedMods(mods) {
    if (!createState && !updatesState && !deleteState) {
      return mods;
    }

    let modsSummary = [...mods, ...createState.filter((row) => row?.target_type === TARGET_TYPE)];

    if (updatesState.length) {
      const updatesForThisTab = updatesState.filter((row) => row?.target_type === TARGET_TYPE);
      modsSummary = modsSummary.map((mod) => {
        const updatedMod = updatesForThisTab.find((uMod) => uMod?.modification_id === mod?.modification_id);
        if (updatedMod) {
          return updatedMod;
        } else {
          return mod;
        }
      });
    }

    if (deleteState.length) {
      modsSummary = modsSummary.filter(
        (mod) => !deleteState.some((dMod) => dMod.modification_id === mod.modification_id),
      );
    }

    return modsSummary;
  }

  function getDataWithModDetails(dataFromSandbox, modsData) {
    return dataFromSandbox?.map((x) => {
      const mods = modsData.filter((mod) => mod.target_id === x?.[ROW_ID]);
      const modsSummary = appendUnsavedMods(mods);

      return modsSummary.reduce(
        (acc, mod) => {
          if (mod.target_id === x?.[ROW_ID]) {
            const value = mod.value_string ?? mod.value_number ?? mod.value_boolean ?? mod.value_datetime;
            acc[mod.field_name] = value;
            acc.sandbox_name = sandboxesQuery?.data?.find((sandbox) => sandbox.sandbox_id === x.sandbox_id)?.name;
          }

          return acc;
        },
        { ...x },
      );
    });
  }

  async function fetchSourceDataFromSandbox(sandboxId, targetIds) {
    return targetIds.length
      ? new Promise(async (resolve) => {
          const dataIdsParam = targetIds.map((id) => `${PLURAL_ROW_ID}=${id}`).join("&") + "&expand=*";

          const endpoint = definitions?.schemas[CONFIG.tabs[activeTab]?.schema]?.endpoints.find(
            (endpoint) => endpoint.method === "get",
          );
          const response = await apiRequest(getAccessToken(), endpoint, { sandbox_id: sandboxId }, null, null, {
            queryParams: dataIdsParam,
          });
          resolve(response);
        })
      : Promise.resolve({ data: [] });
  }

  function constructFullObjectFromMods(modsData) {
    return modsData.reduce((acc, mod) => {
      acc[ROW_ID] = mod.target_id;
      acc[mod.field_name] = mod.value_string ?? mod.value_number ?? mod.value_boolean ?? mod.value_datetime;
      const modSandbox = mod.reference_sandbox_id ?? environmentQuery.data?.base_sandbox_id;
      acc.sandbox_name = sandboxesQuery?.data?.find((sandbox) => sandbox.sandbox_id === modSandbox)?.name;
      return acc;
    }, {});
  }

  async function syncModsWithData() {
    //for each activeSandbox, get a list of target_ids for each sandbox. in a hashmap, key is sandbox_id, value is array of target_ids

    const targetIdMap = activeSandboxIds?.reduce((acc, sandboxId) => {
      acc[sandboxId] = [
        ...new Set([
          ...modsQuery?.data.data
            .filter((mod) => {
              if (!mod?.reference_sandbox_id && mod.target_type === TARGET_TYPE) {
                // If the mod has no reference sandbox id, assume it is a mod for the base sandbox. Keeping this nullable for legacy reasons
                return mod.target_type === TARGET_TYPE && sandboxId === environmentQuery.data?.base_sandbox_id;
              } else {
                return mod.target_type === TARGET_TYPE && mod?.reference_sandbox_id === sandboxId;
              }
            })
            .map((mod) => mod.target_id),
          ...createState
            .filter((mod) => {
              if (!mod?.reference_sandbox_id) {
                // If the mod has no reference sandbox id, assume it is a mod for the base sandbox. Keeping this nullable for legacy reasons
                return mod.target_type === TARGET_TYPE && sandboxId === environmentQuery.data?.base_sandbox_id;
              } else {
                return mod.target_type === TARGET_TYPE && mod?.reference_sandbox_id === sandboxId;
              }
            })
            .map((mod) => mod.target_id),
        ]),
      ];
      return acc;
    }, {});

    // for each sandbox id in targetIds, make sure that there's something. if not, set the table data to empty
    if (!Object.keys(targetIdMap).length) {
      setDefaultTableData([]);
      setTableData([]);
      setSelectedRow();
      setRowSelectionModel({});
      return;
    }

    try {
      // promise.all for each sandbox id in targetIds
      // for each sandbox id in targetIds, get the data for each target_id
      const fetchPromises = Object.entries(targetIdMap).map(([sandboxId, ids]) => {
        const useableSandboxId =
          sandboxId === environmentQuery.data?.base_sandbox_id && scheduleId
            ? environmentQuery.data?.sandbox_id
            : sandboxId;
        return fetchSourceDataFromSandbox(useableSandboxId, ids);
      });

      const allDataForMods = await Promise.all(fetchPromises).then((data) => {
        return data.reduce((acc, d) => {
          if (d?.[CONFIG.tabs[activeTab]?.allResponseKey ?? "data"]) {
            acc.push(...d[CONFIG.tabs[activeTab]?.allResponseKey ?? "data"]);
          } else {
            if (Array.isArray(d) && d.length) {
              acc.push(...d);
            }
          }
          return acc;
        }, []);
      });

      const leftovers = Object.entries(targetIdMap).reduce((acc, [sandboxId, ids]) => {
        const fetchedIds = allDataForMods.map((x) => x?.[ROW_ID]);
        const missingIds = ids.filter((id) => !fetchedIds.includes(id));
        if (missingIds.length) {
          acc.push(...missingIds);
        }
        return acc;
      }, []);

      const groupedLeftovers = modsQuery?.data?.data
        ?.filter((mod) => leftovers.includes(mod.target_id))
        .reduce((acc, mod) => {
          if (!acc[mod.target_id]) {
            acc[mod.target_id] = [];
          }
          acc[mod.target_id].push(mod);
          return acc;
        }, {});

      const dataWithModDetails = getDataWithModDetails(allDataForMods, modsQuery?.data?.data);

      Object.entries(groupedLeftovers).forEach(([targetId, mods]) => {
        const fullObject = constructFullObjectFromMods(mods);
        dataWithModDetails.push(fullObject);
      });

      setDefaultTableData(dataWithModDetails.filter((x) => x?.[ROW_ID]));
      setTableData(dataWithModDetails.filter((x) => x?.[ROW_ID]));

      if (selectedRow) {
        const syncedRow = dataWithModDetails.find((row) => {
          return (
            row?.[ROW_ID] !== undefined &&
            selectedRow?.[ROW_ID] !== undefined &&
            row?.[ROW_ID] === selectedRow?.[ROW_ID]
          );
        });
        if (!syncedRow) {
          setRowSelectionModel({});
        } else {
          processSelectedRow(syncedRow);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (Object.keys(rowSelectionModel).length > 0) {
      setDrawerWidth(defaultDrawerWidth);
    } else {
      setDrawerWidth(0);
    }
  }, [selectedRow, rowSelectionModel]);

  useEffect(() => {
    if (currentSandboxQuery?.data?.sandbox_id) {
      setSelectedSandbox(currentSandboxQuery?.data?.sandbox_id);
    }
  }, [currentSandboxQuery?.data?.sandbox_id]);
  // On load, fetch the associated data, or whatever the tab is from each sandbox, given the target_id of each mod, then resplice the mod data back into the results and put them in the table
  useEffect(() => {
    if (!modsQuery?.data?.data?.length) {
      setDefaultTableData([]);
      setTableData([]);
      setSelectedRow();
      setRowSelectionModel({});
      return;
    }

    syncModsWithData();
  }, [modsQuery?.data?.data, currentSandboxQuery?.data?.sandbox_id, activeTab]);

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(isDirty);
  }, [isDirty]);

  // Because of the addition of the delete_overlay_mod, we need to re-process the selected row to get the correct fields that have a delete override. delete_overlay_mod is more like a piece of metadata whose presence indicates an operation to take rather than the concrete values to update the record with.
  useEffect(() => {
    processSelectedRow(selectedRow);
  }, [createState, updatesState, deleteState]);

  const loadMessage = "Retrieving the latest data, please wait...";

  return environmentQuery?.isPending || overlaysQuery?.isError || overlaysQuery?.isPending ? (
    <LoadingMask loadMessage={loadMessage} />
  ) : overlaysQuery?.data?.length === 0 ? (
    <div className="p-10 max-w-[700px] text-base leading-tight">
      <p className="text-zinc-300 mb-6">
        It looks like you don't have any overlays set up yet for this environment. This feature allows for your team to
        easily override values that exist in the sandbox that this environment uses. Enabling an overlay will invisibly
        generate a copy of the sandbox, but with the values provided in this table. The game will automatically point to
        this generated sandbox. There will be no difference in game performance.
      </p>
      <p className="mb-6">Would you like to enable overlays and use this Overrides section?</p>
      <div
        onClick={enableOverlay}
        className="bg-violet-600 text-white font-medium rounded-sm text-center inline-flex items-center z-10 py-2 px-3 gap-2 transition-colors duration-200 ease-in-out hover:bg-violet-500 cursor-pointer"
      >
        Enable Overlays
      </div>
    </div>
  ) : (
    <div className={`relative flex z-10 overflow-hidden ${className} ${scheduleId ? "" : "h-[calc(100vh-50px)]"}`}>
      {drawerLoadingMessage && (
        <div className="absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="mt-20">
            <LoadingMask size={100} loadMessage={drawerLoadingMessage} />
          </div>
        </div>
      )}
      <div
        className="relative"
        style={{
          width: `calc(100% - ${drawerWidth}px)`,
          transition: isResizing.current ? "none" : "width 0.3s ease-in-out",
        }}
      >
        <MenuBar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          buttons={buttonsMap}
          setSelectedRow={setSelectedRow}
          setRowSelectionModel={setRowSelectionModel}
        />
        <div className="flex justify-end bg-[#121212] p-2 overflow-hidden">
          <div
            className={`${tableSize} flex items-center justify-center px-2 rounded-sm bg-red-500/50 transition-opacity mr-2 ${
              isDirty ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
          >
            <p className="text-white font-medium">You have unsaved changes</p>
          </div>
          <div className="flex items-center relative overflow-visible">
            <div
              className={`flex gap-2 transition-all duration-300 relative z-20 ${
                isDirty && editPermitted ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
              }`}
            >
              <button
                onClick={handleCancel}
                className={`bg-zinc-600 text-white font-medium rounded-sm text-center px-1 py-1 transition-colors duration-200 ease-in-out hover:bg-zinc-700 w-20 ${tableSize}`}
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className={`bg-violet-600 text-white font-medium rounded-sm text-center px-1 py-1 transition-colors duration-200 ease-in-out hover:bg-violet-700 w-20 ${tableSize} ${saving ? "opacity-50 pointer-events-none" : ""}`}
              >
                {saving ? "Saving..." : "Save"}
              </button>
            </div>

            {showSuccessMessage && (
              <div className="absolute top-1/2 right-3 transform -translate-y-1/2 animation-fade-in-out">
                <div className="p-1 text-xs rounded-sm font-medium text-green-500 border border-green-500/20 bg-green-900/20">
                  Success
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`text-red-500 grid transition-all px-3 ${
            updateMutation.isError || !!error ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
          }`}
        >
          <p
            className={`overflow-hidden font-lato border-red-500/20 rounded-sm ${
              updateMutation.isError || !!error ? "p-2 border-2 mt-3" : "p-0 border-0"
            } box-border`}
          >
            {updateMutation.isError ? updateMutation.error : ""}
            {error && error.split("\n").map((error) => <Fragment key={error}>{error}</Fragment>)}
          </p>
        </div>
        {!singleSelectOptions ? (
          <div className="mt-20 flex-1">
            <LoadingMask loadMessage={loadMessage} />
          </div>
        ) : (
          <div className="flex p-2 justify-between items-center">
            <div className="flex gap-4">
              {sandboxOptions && (
                <div className="w-[320px]">
                  <BasicSelect
                    label="Select Reference Sandbox"
                    name="sandbox"
                    value={selectedSandbox}
                    onChange={(e) => setSelectedSandbox(e?.target?.value)}
                    options={sandboxOptions}
                    required={false}
                    disabled={false}
                    placeholder="Select Sandbox"
                    description="The sandbox to which overrides will be applied."
                  />
                </div>
              )}
              <div
                className={`w-[320px] ${
                  singleSelectOptions.length === 0 && !searchAndSelectValue ? "opacity-40 pointer-events-none" : ""
                }`}
              >
                <SearchAndSelect
                  label={`Select ${activeTab} to add`}
                  name={activeTab}
                  onSelect={addToTable}
                  onChange={setSearchAndSelectValue}
                  options={singleSelectOptions.filter(
                    (fullObject) => !tableData.some((x) => x?.[ROW_ID] === fullObject.id),
                  )}
                  required={false}
                  disabled={false}
                  placeholder={`Select ${activeTab} to add`}
                  description={`The ${activeTab} to which overrides will be applied.`}
                  supplementalButtons={
                    activeTab === "KV" &&
                    searchAndSelectValue &&
                    !singleSelectOptions.find((x) => x.title === searchAndSelectValue) &&
                    !tableData.find((x) => x?.[ROW_ID] === searchAndSelectValue) &&
                    !tableData.find((x) => x?.original?.[ROW_ID] === searchAndSelectValue)
                      ? [
                          {
                            label: "Add New",
                            onClick: () => {
                              addToTable({ id: searchAndSelectValue, title: searchAndSelectValue });
                            },
                          },
                        ]
                      : []
                  }
                />
              </div>
            </div>
            <p className={`text-zinc-300 ${tableSize} font-medium px-1`}>
              <span className="pb-1 border-b border-b-zinc-300/20">
                Current Sandbox: {currentSandboxQuery?.data?.name}
              </span>
            </p>
          </div>
        )}
        <DataTable
          data={tableData}
          columns={columns}
          columnVisibility={defaultColumnVisibility}
          // When I set up column visbility options:
          // columnVisibility={columnVisibility}
          // setColumnVisibility={setColumnVisibility}
          columnsWithSort={["name", "key", "sandbox_name"]}
          setSelectedRow={processSelectedRow}
          selectedRow={selectedRow?.original || selectedRow}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          defaultSortKey={ROW_ID}
          rowId={ROW_ID}
          className="flex-1"
          readPermitted={readPermitted}
          editPermitted={editPermitted}
          className={`overflow-y-auto pb-48`}
          isModal={scheduleId}
        />
      </div>
      <RightDrawer setWidthFn={setDrawerWidth} width={drawerWidth} isResizing={isResizing}>
        {selectedRow && (
          <div className={`${scheduleId ? "h-[calc(70vh-35px)]" : ""}`}>
            <OverridesForm
              item={selectedRow?.original || selectedRow}
              setRowSelectionModel={setRowSelectionModel}
              setDrawerLoadingMessage={setDrawerLoadingMessage}
              config={CONFIG?.tabs[activeTab]}
              tab={activeTab}
              setSelectedRow={processSelectedRow}
              buttonsMap={drawerButtonsMap}
              readPermitted={readPermitted}
              editPermitted={editPermitted}
              sandboxId={selectedRow?.original?.sandbox_id || currentSandboxQuery?.data?.sandbox_id}
              onChange={handleChange}
              onDelete={handleDelete}
              showModal={showModal}
              setShowModal={setShowModal}
              onCheckToEnable={onCheckToEnable}
              enabledFields={enabledFields}
              deleteOverrideFields={deleteOverrideFields}
              formClassName={scheduleId ? "overflow-y-auto h-full" : "overflow-y-auto h-[calc(100vh-140px)]"}
              rowOverrides={rowOverrides}
            />
          </div>
        )}
      </RightDrawer>
      {(modsQuery?.isPending || modsQuery?.isFetching) && (
        <div className="mt-20 flex-1 absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <LoadingMask loadMessage={loadMessage} />
        </div>
      )}
    </div>
  );
}
