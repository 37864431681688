import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import RHEditableTable from "../RHEditableTable";
import { apiRequest, useApiToken } from "../../../common/apiUtils";
import * as helpers from "../../../common/helpers";
import { breakPointColumns, breakPointRowKey, breakPointEntry } from "../../../views/sandbox/definitions";

// Create a modified version of breakPointColumns to ensure price_break_point_id is visible
const modifiedBreakPointColumns = breakPointColumns.map((column) => {
  if (column.field === "price_break_point_id") {
    return {
      ...column,
      type: "text", // Change from "metadata" to "text" to ensure visibility
      alwaysHidden: false,
      headerName: "Breakpoint ID",
    };
  } else if (column.field === "currencies") {
    return {
      ...column,
      alwaysHidden: true,
    };
  }
  return column;
});

const PriceConfigBreakpoints = ({ selectedItem, sandbox, classes, pageData }) => {
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });
  const [selectedTableRow, setSelectedTableRow] = useState(selectedItem);
  const token = useApiToken();

  useEffect(() => {
    setSelectedTableRow(selectedItem);
  }, [selectedItem]);

  const childTableFriendlyName = "Breakpoints";
  const childTableApi = "price-point";

  const tabModel = [
    { tabKey: "current_breakpoints", tabLabel: "Current Breakpoints" },
    { tabKey: "pre_sale_breakpoints", tabLabel: "Pre-sale Breakpoints" },
  ];

  const tableInitialState = {
    pagination: {
      paginationModel: {
        pageSize: 5,
      },
    },
    columns: {
      columnVisibilityModel: {
        price_break_point_id: true,
        quantity: true,
        currencies: false,
      },
    },
    sorting: {
      sortModel: [{ field: "price_break_point_id", sort: "asc" }],
    },
  };

  const refreshData = async () => {
    try {
      const response = await apiRequest(
        token,
        { path: `/v1/sandbox/{sandbox_id}/price-point?expand=*`, method: "get" },
        { sandbox_id: sandbox?.sandboxId },
        {},
        {},
        {},
      );

      // Find the updated item that matches our current selectedTableRow
      if (response?.data && Array.isArray(response.data)) {
        const updatedItem = response.data.find((item) => item.price_point_id === selectedTableRow.price_point_id);
        if (updatedItem) {
          setSelectedTableRow(updatedItem);
        }
      }

      return response;
    } catch (error) {
      console.error("Error refreshing data:", error);
    }
  };

  const handleBreakpointUpdate = async (
    apiName,
    onDataRefresh,
    rowId,
    sandboxId,
    editedItem,
    customArgs,
    selectedTab,
  ) => {
    const prunedCurrencies = editedItem?.currencies.map((item) => {
      if (item?.price_break_point_currency_id === "") {
        delete item.price_break_point_currency_id;
      }
      return item;
    });

    const breakpoints = selectedTableRow[selectedTab]
      ? selectedTableRow[selectedTab].reduce((acc, item) => {
          if (item?.price_break_point_id === editedItem?.price_break_point_id) {
            acc.push({ ...editedItem, currencies: prunedCurrencies });
            return acc;
          }
          acc.push(item);
          return acc;
        }, [])
      : [];

    const mappedEditedItem = {
      ...selectedTableRow,
      [selectedTab]: breakpoints,
    };

    try {
      const item = await apiRequest(
        token,
        { path: `/v1/sandbox/{sandbox_id}/price-point/{price_point_id}`, method: "put" },
        {
          sandbox_id: sandboxId,
          price_point_id: selectedTableRow.price_point_id,
        },
        mappedEditedItem,
        {},
        {},
      );

      await refreshData();
      setSelectedTableRow(item);
      return item;
    } catch (error) {
      console.error("Error updating breakpoint:", error);
      throw error;
    }
  };

  const handleBreakpointCreate = async (apiName, onDataRefresh, sandboxId, editedItem, customArgs, selectedTab) => {
    const prunedCurrencies = editedItem?.currencies.map((item) => {
      if (item?.price_break_point_currency_id === "") {
        delete item.price_break_point_currency_id;
      }
      return item;
    });

    let breakpoints;
    if (!selectedTableRow?.[selectedTab] || selectedTableRow?.[selectedTab].length === 0) {
      breakpoints = [{ ...editedItem, currencies: prunedCurrencies }];
    } else {
      breakpoints = [...selectedTableRow?.[selectedTab], { ...editedItem, currencies: prunedCurrencies }];
    }

    const mappedEditedItem = {
      ...selectedTableRow,
      [selectedTab]: breakpoints,
    };

    try {
      const item = await apiRequest(
        token,
        { path: `/v1/sandbox/{sandbox_id}/price-point/{price_point_id}`, method: "put" },
        {
          sandbox_id: sandboxId,
          price_point_id: selectedTableRow.price_point_id,
        },
        mappedEditedItem,
        {},
        {},
      );

      await refreshData();
      setSelectedTableRow(item);
      return item;
    } catch (error) {
      console.error("Error creating breakpoint:", error);
      throw error;
    }
  };

  const handleBreakpointDelete = async (
    apiName,
    onDataRefresh,
    rowId,
    sandboxId,
    selectedItem,
    customArgs,
    selectedTab,
  ) => {
    const breakpoints = selectedTableRow[selectedTab].reduce((acc, item) => {
      if (item?.price_break_point_id !== rowId) {
        acc.push(item);
      }
      return acc;
    }, []);

    const mappedEditedItem = {
      ...selectedTableRow,
      [selectedTab]: breakpoints,
    };

    try {
      const item = await apiRequest(
        token,
        { path: `/v1/sandbox/{sandbox_id}/price-point/{price_point_id}`, method: "put" },
        {
          sandbox_id: sandboxId,
          price_point_id: selectedTableRow.price_point_id,
        },
        mappedEditedItem,
        {},
        {},
      );

      await refreshData();
      setSelectedTableRow(item);
      return item;
    } catch (error) {
      console.error("Error deleting breakpoint:", error);
      throw error;
    }
  };

  return (
    <>
      {selectedTableRow && (
        <Box sx={{ flex: "1", paddingTop: "20px" }}>
          <RHEditableTable
            title={childTableFriendlyName}
            friendlyName={childTableFriendlyName}
            apiName={childTableApi}
            rowKey={breakPointRowKey}
            columns={breakPointColumns}
            tableData={selectedTableRow}
            initialState={tableInitialState}
            sandbox={sandbox}
            className={classes}
            setSnackBarState={setSnackBarState}
            setSnackBar={setSnackBar}
            editableInline={false}
            newModel={breakPointEntry}
            newModelArgs={[sandbox?.sandboxId, selectedTableRow?.price_point_id]}
            onDataRefresh={refreshData}
            createHandler={handleBreakpointCreate}
            updateHandler={handleBreakpointUpdate}
            deleteHandler={handleBreakpointDelete}
            tabModel={tabModel}
          />
        </Box>
      )}
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
};

export default PriceConfigBreakpoints;
