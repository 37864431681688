import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { apiRequest } from "common/apiUtils";
import { createPortal } from "react-dom";
import { motion, AnimatePresence } from "framer-motion";
import Tooltip from "components/common/FormField/Tooltip";
import { CloseIcon } from "assets/svgs";
import { debounce } from "common/helpers";
import { useAuth } from "contexts/AuthContext";

import { useLayout } from "contexts/LayoutContext";
import definitions from "common/definitions";

const SingleDynamicSelect = React.memo((props) => {
  const {
    label,
    name,
    value,
    onChange,
    required,
    disabled,
    placeholder,
    description,
    pageData,
    sandboxId,
    onCheckToEnable,
  } = props;

  const { getAccessToken } = useAuth();
  const { tableSize } = useLayout();

  const componentRef = useRef(null);
  const portalRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [typeaheadValue, setTypeaheadValue] = useState();
  const [currentValueName, setCurrentValueName] = useState();
  const [fetchedOptions, setFetchedOptions] = useState([]);

  const endpoints = definitions?.schemas?.[pageData?.dynamicSelectFields?.[name]?.tableData]?.endpoints;
  const fieldId = pageData?.dynamicSelectFields?.[name]?.idKey;
  const dataName = pageData?.dynamicSelectFields?.[name]?.responseDataName;

  const GET_OPTIONS_ENDPOINT = endpoints?.find((endpoint) => endpoint.method === "get");
  const replacements = { sandbox_id: sandboxId };

  async function getFetchOptions(typeaheadValue) {
    let extraOptions = {
      queryParams: {
        page_size: 36,
      },
    };

    if (typeaheadValue) {
      extraOptions.queryParams.name = typeaheadValue;
    }

    const response = await apiRequest(
      await getAccessToken(),
      GET_OPTIONS_ENDPOINT,
      replacements,
      null,
      null,
      extraOptions,
    );

    return response;
  }

  async function getDisplayValue() {
    if (!value) return [];

    let extraOptions = {
      queryParams: {
        page_size: 36,
        [`${fieldId}s`]: value,
      },
    };

    const response = await apiRequest(
      await getAccessToken(),
      GET_OPTIONS_ENDPOINT,
      replacements,
      null,
      null,
      extraOptions,
    );
    return response;
  }

  const optionsQuery = useQuery({
    queryKey: ["dynamicSelectOptions", name, typeaheadValue, sandboxId],
    queryFn: () => getFetchOptions(typeaheadValue),
    enabled: !!GET_OPTIONS_ENDPOINT,
    staleTime: 1000 * 60 * 5,
  });

  const displayQuery = useQuery({
    queryKey: ["dynamicSelectDisplay", value, sandboxId],
    queryFn: () => getDisplayValue(),
    enabled: !!GET_OPTIONS_ENDPOINT,
    staleTime: 1000 * 60 * 5,
  });

  function updatePortalPosition() {
    if (componentRef.current) {
      const rect = componentRef.current.getBoundingClientRect();
      return {
        position: "absolute",
        top: `${rect.bottom + window.scrollY + 2}px`,
        left: `${rect.left + window.scrollX}px`,
        width: `${rect.width}px`,
      };
    }
    return {};
  }

  const updateTypeaheadValue = debounce((event) => {
    if (event.target.value) {
      setTypeaheadValue(event.target.value);
    } else {
      setTypeaheadValue("");
    }
  }, 200);

  function selectOption(option) {
    setIsOpen(false);
    setTypeaheadValue();
    const event = { target: { value: option?.[fieldId] ?? "" } };
    onChange(event, name);
  }

  useEffect(() => {
    if (optionsQuery.data) {
      setFetchedOptions(optionsQuery.data?.[dataName]);
    }
  }, [optionsQuery.data]);

  useEffect(() => {
    if (displayQuery?.data?.[dataName]?.[0]) {
      setCurrentValueName(displayQuery?.data?.[dataName]?.[0]?.name);
    } else {
      setCurrentValueName(<span className="text-zinc-400/60">None Selected</span>);
    }
  }, [displayQuery?.data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target) &&
        portalRef.current &&
        !portalRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="mb-3 container-query-formfield relative">
      <div className="cq-wrapper relative">
        {label && (
          <label
            className={`block font-medium text-zinc-300 font-lato ${
              tableSize === "text-xs" || tableSize === "text-sm" ? "text-xs" : "text-sm"
            }`}
          >
            <Tooltip title={description} placement="left" disableInteractive>
              <p className="mb-1 cq-label">
                <span>{`${label} ${required ? "*" : ""}`}</span>
              </p>
            </Tooltip>
          </label>
        )}
        <div className="flex w-full gap-1.5 items-stretch">
          <div
            className={`text-zinc-300 relative bg-zinc-700/20 border p-[7px] box-border focus:outline-violet-600 outline-violet-600 w-full cursor-pointer ${tableSize} ${isOpen ? "rounded-bl-none rounded-br-none rounded-tl-[4px] rounded-tr-[4px] border-zinc-500" : "rounded-[4px] border-zinc-600"} ${disabled ? "opacity-50 pointer-events-none" : ""}`}
            onClick={() => setIsOpen((prev) => !prev)}
            ref={componentRef}
          >
            {currentValueName ?? value}
            {value && (
              <div
                className="absolute right-2 top-1/2 -translate-y-1/2"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectOption({});
                }}
              >
                <CloseIcon className="hover:fill-zinc-100 transition-colors duration-200 ease-in-out" />
              </div>
            )}
          </div>
          {onCheckToEnable && (
            <label className="flex items-center p-1 border border-zinc-600 rounded-[4px]">
              <p
                className={`mx-1 text-left text-zinc-300 font-medium inline-block text-nowrap select-none ${tableSize === "text-xs" ? "text-xs" : "text-sm"}`}
              >
                Enable Override
              </p>
              <input
                className="accent-violet-600"
                type="checkbox"
                onChange={(e) => onCheckToEnable(e, name, value)}
                checked={!disabled}
              />
            </label>
          )}
        </div>

        {isOpen &&
          createPortal(
            <AnimatePresence>
              <motion.div
                style={updatePortalPosition()}
                ref={portalRef}
                className={`bg-zinc-800 text-zinc-300 border border-zinc-500 z-[100] max-h-[300px] overflow-y-auto p-1 rounded-tl-none rounded-tr-none rounded-bl-md rounded-br-md shadow-2xl`}
                initial={{ opacity: 0, height: 0, y: -10 }}
                animate={{
                  opacity: 1,
                  height: "auto",
                  y: 0,
                  transition: {
                    height: { duration: 0.1 },
                    opacity: { duration: 0.1 },
                    y: { duration: 0.1 },
                  },
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                  y: -10,
                  transition: {
                    height: { duration: 0.1 },
                    opacity: { duration: 0.1 },
                    y: { duration: 0.1 },
                  },
                }}
              >
                <input
                  className={`bg-transparent w-full text-zinc-300 py-1 px-2 mb-1 border border-transparent border-b-zinc-600 ${tableSize}`}
                  type="text"
                  onChange={updateTypeaheadValue}
                  placeholder={"Please type to search"}
                />
                <AnimatePresence mode="popLayout" initial={false}>
                  {fetchedOptions.map((option) => (
                    <motion.div
                      layout
                      key={option?.[fieldId]}
                      className={`cursor-pointer p-2 flex items-center ${tableSize} last:pb-0 justify-between bg-zinc-700/20 hover:bg-zinc-700`}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{
                        opacity: 1,
                        scale: 1,
                        transition: { type: "spring", stiffness: 300, damping: 25, opacity: { duration: 0.1 } },
                      }}
                      exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.1 } }}
                      onClick={() => selectOption(option)}
                    >
                      <p>
                        {option["name"]} <span className="text-zinc-400">({option[fieldId]})</span>
                      </p>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </motion.div>
            </AnimatePresence>,
            document.body,
          )}
      </div>
    </div>
  );
});

export default SingleDynamicSelect;
