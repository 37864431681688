import { useEffect, useState } from "react";
import { useLayout } from "contexts/LayoutContext";
import Tooltip from "components/common/FormField/Tooltip";
import { EyeIcon, EyeSlashIcon } from "assets/svgs";
export default function TextField({
  label,
  name,
  value,
  onChange,
  placeholder,
  required,
  disabled = false,
  type = "text",
  description,
  hasError,
  onCheckToEnable,
  className,
}) {
  const [error, setError] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const [showPassword, setShowPassword] = useState(false);
  const { tableSize } = useLayout();
  const taId = Date.now();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const validateURL = (url) => {
    const re = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
    return re.test(url);
  };

  const validateUUID = (uuid) => {
    const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return re.test(uuid);
  };

  const validateIPAddress = (ip) => {
    const ipv4 =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const ipv6 = /^([0-9a-f]{1,4}:){7}[0-9a-f]{1,4}$/i;
    return ipv4.test(ip) || ipv6.test(ip);
  };

  const validationMap = {
    email: validateEmail,
    url: validateURL,
    uuid: validateUUID,
    ip: validateIPAddress,
  };

  const handleOnChange = (event) => {
    onChange(event);
    return;
  };

  const handleBlur = () => {
    // Ensure localValue is a string before calling trim()
    const valueAsString = typeof localValue === "string" ? localValue : String(localValue);

    if (valueAsString.trim() !== "") {
      const isError = fieldType in validationMap && !validationMap[fieldType](valueAsString);
      setError(isError);
      if (handleValidationError) {
        const helperText = isError ? getHelperText(isError, fieldType) : "";
        handleValidationError(fieldName, isError, helperText);
      }
    }
  };

  const getHelperText = (isError, type) => {
    if (isError ? isError : error) {
      switch (type) {
        case "email":
          return "Please enter a valid email";
        case "url":
          return "Please enter a valid URL";
        case "uuid":
          return "Please enter a valid UUID";
        case "ip":
          return "Please enter a valid IP address";
        default:
          return "Invalid input";
      }
    }
    return "";
  };

  function adjustTextareaHeight(textarea) {
    if (!textarea) return;

    const computedStyle = window.getComputedStyle(textarea);
    const paddingTop = parseFloat(computedStyle.paddingTop);
    const paddingBottom = parseFloat(computedStyle.paddingBottom);

    textarea.style.height = `${textarea.scrollHeight - paddingTop - paddingBottom}px`;
  }

  useEffect(() => {
    setLocalValue(value);
    adjustTextareaHeight(document.querySelector("taId"));
  }, [value]);

  return (
    <div className={`mb-3 container-query-formfield ${className}`}>
      <div className={`cq-wrapper ${type === "textarea" ? "textarea" : ""}`}>
        {label && (
          <label
            htmlFor={name}
            className={`block font-medium text-zinc-300 font-lato ${
              tableSize === "text-xs" || tableSize === "text-sm" ? "text-xs" : "text-sm"
            } ${disabled ? "opacity-70" : ""}`}
          >
            <Tooltip title={description} placement="left" disableInteractive>
              <p className={`mb-1 cq-label ${type === "textarea" ? "textarea" : ""}`}>
                <span>{`${label} ${required ? "*" : ""}`}</span>
              </p>
            </Tooltip>
          </label>
        )}
        <div className="flex gap-2 w-full">
          {type === "textarea" ? (
            <textarea
              className={`text-white w-full border border-zinc-600 rounded p-2 focus:outline-none focus:ring-2 focus:ring-violet-500 placeholder-zinc-400 bg-opacity-20 bg-zinc-700 backdrop-filter backdrop-blur-sm ${tableSize} w-full textarea-${taId} cq-input h-[34px] transition-all ${disabled ? "opacity-60" : ""}`}
              description="Value"
              style={{ minHeight: "34px" }}
              name="Value"
              label="Value"
              value={value}
              onChange={(e) => {
                handleOnChange(e);
                adjustTextareaHeight(e.target);
              }}
            />
          ) : (
            <div className="relative w-full cq-input">
              <input
                type={showPassword ? "text" : type}
                name={name}
                value={value || ""}
                onChange={handleOnChange}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                className={`text-white w-full border border-zinc-600 rounded p-2 focus:outline-none focus:ring-2 focus:ring-violet-500 placeholder-zinc-400 bg-opacity-20 bg-zinc-700 backdrop-filter backdrop-blur-sm ${tableSize} ${disabled ? "opacity-60" : ""}`}
              />
              {type === "password" && (
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </button>
              )}
            </div>
          )}
          {onCheckToEnable && (
            <label className="flex items-center p-2 border border-zinc-600 rounded-[4px]">
              <p
                className={`mx-1 text-left text-zinc-300 font-medium inline-block select-none text-nowrap ${tableSize === "text-xs" ? "text-xs" : "text-sm"}`}
              >
                Enable Override
              </p>
              <input
                className="accent-violet-600"
                type="checkbox"
                onChange={(e) => onCheckToEnable(e, name, value)}
                checked={!disabled ? true : false}
              />
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
