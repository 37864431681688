import { useState, useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import { useQuery, useMutation, keepPreviousData } from "@tanstack/react-query";
import { PageData } from "types/PageData";

import definitions from "common/definitions.json";

declare global {
  interface Window {
    env: {
      REACT_APP_API_BASE_PATH: string;
      [key: string]: string;
    };
  }
}

interface Definitions {
  schemas: {
    [key: string]: {
      endpoints: Endpoint[];
    };
  };
}

const assertedDefinitions = definitions as Definitions;

interface Endpoint {
  path: string;
  method: string;
}

interface ApiRequestOptions {
  queryParams?: string | Record<string, string>;
  fetchOptions?: RequestInit;
}

interface TableDataOptions {
  data: any;
  replacements?: Replacements;
  extraHeaders?: Record<string, string>;
  extraOptions?: ApiRequestOptions;
}

interface PortalQueryConfig {
  version?: string;
  schemas?: {
    GETparams?: Record<string, string> | string;
    POSTparams?: Record<string, string> | string;
    PUTparams?: Record<string, string> | string;
  };
}

interface PortalQueryOptions {
  schema: string;
  token: string;
  replacements?: Replacements;
  data?: any;
  method?: string;
  extraOptions?: ApiRequestOptions;
  configs?: PortalQueryConfig;
  newModel?: boolean;
}

interface MutationFunctionArgs {
  data?: Record<string, any>;
  schemaOverride?: string;
  configsOverride?: PortalQueryConfig;
  replacementsOverride?: Record<string, any>;
  extraOptionsOverride?: { queryParams: string };
}

type ReplacementKey = string;
type ReplacementValue = string | number;
type Replacements = Record<ReplacementKey, ReplacementValue>;

// First, let's define proper types for the callbacks
type MutationData = any; // Replace 'any' with your actual data type
type MutationVariables = any; // Replace 'any' with your actual variables type
type MutationContext = any; // Replace 'any' with your actual context type
type MutationError = any; // Replace 'any' with your actual error type

type CallBackOptions = {
  onSuccessCallback?: (data: MutationData, variables: MutationVariables, context: MutationContext) => void;
  onErrorCallback?: (error: MutationError, variables?: MutationVariables, context?: MutationContext) => void;
  onMutateCallback?: (variables: MutationVariables) => Promise<void>;
  onSettledCallback?: (
    data: MutationData | undefined,
    error: MutationError | null,
    variables: MutationVariables,
    context: MutationContext,
  ) => void;
};

interface PortalMutationOptions {
  queryClient: any;
  schema: string;
  token: string;
  replacements?: Record<string, any>;
  configs?: PortalQueryConfig;
  method?: string;
  extraHeaders?: Record<string, string>;
  extraOptions?: ApiRequestOptions;
  onSuccessCallback?: (data: any, variables: any, context: any) => void;
  onErrorCallback?: (error: any) => void;
  onMutateCallback?: (variables: any) => Promise<void>;
  onSettledCallback?: (data: any, error: any, variables: any, context: any) => void;
}

interface FetchDirectlyArgs {
  fullUrl?: string;
  sandboxId?: string;
  endpoint?: string;
  dataId?: string | number;
  method: string;
  token: string;
  body?: Record<string, any>;
}

interface FetchDirectlyParams {
  [key: string]: string;
}

interface ApiError extends Error {
  response?: {
    data?: {
      desc?: string;
      detail?:
        | Array<{
            msg?: string;
            type?: string;
            loc?: string[];
          }>
        | string;
    };
    status?: number;
  };
  errorMsg?: string;
}

export const API_BASE =
  process.env.NODE_ENV === "production" ? window.env.REACT_APP_API_BASE_PATH : process.env.REACT_APP_API_BASE_PATH;

export const apiRequest = async (
  token: string,
  endpoint: Endpoint,
  replacements: Replacements,
  body: Record<string, any> | null = null,
  extraHeaders: Record<string, string> | null = {},
  extraOptions: ApiRequestOptions = {},
  pageData: PageData = {},
) => {
  let url = API_BASE! + endpoint?.path;

  if (extraOptions.queryParams) {
    let processedParams = extraOptions.queryParams;
    if (typeof extraOptions.queryParams === "object" && extraOptions.queryParams !== null) {
      processedParams = Object.entries(extraOptions.queryParams as Record<string, string>).reduce(
        (acc, [key, val], i) => {
          if (val) {
            acc += `${key}=${val}${i < Object.keys(extraOptions.queryParams as Record<string, string>).length - 1 ? "&" : ""}`;
          }
          return acc;
        },
        "",
      );
    } else if (typeof extraOptions.queryParams === "string") {
      processedParams = new URLSearchParams(extraOptions.queryParams).toString();
    }

    url += `?${processedParams}`;
  }

  // Extract placeholders from the URL
  const placeholderMatches = url.match(/\{[^\}]+\}/g) || [];

  for (const placeholder of placeholderMatches) {
    let key = placeholder.slice(1, -1);

    // Special ugh for xp_threshold_id
    if (key === "xp_threshold_id") {
      key = "xp_level_threshold_id";
    }
    // Special ugh x2 for sandbox_id
    if (key === "sandbox_identifier") {
      key = "sandbox_id";
    }
    // Special ugh x3 for inventory_bucket_rule_set_id
    if (key === "inventory_bucket_use_rule_set_id") {
      key = "rule_set_id";
    }

    let value = body?.[key] || replacements?.[key] || pageData.SelectedRecord?.[key];

    if (value) {
      url = url.replace(placeholder, value);
    }
  }

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    ...extraHeaders,
  });

  const options = {
    method: endpoint?.method.toUpperCase(),
    headers: headers,
    ...extraOptions.fetchOptions,
  };

  if (body && ["PUT", "PATCH", "DELETE"].includes(endpoint?.method.toUpperCase())) {
    options.body = JSON.stringify(body);
  }

  if (body && endpoint?.method.toUpperCase() === "POST") {
    let modifiedBody = body;

    //ugh
    if (pageData.PostDataArrayName === "data") {
      modifiedBody = { data: [body] };
    }

    options.body = JSON.stringify(modifiedBody);
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      let errorMsg = "An error occurred while fetching data";

      // Check if the response has content before attempting to parse as JSON
      if (response.headers.get("content-type")?.includes("application/json")) {
        try {
          const errorData = await response.json();
          errorMsg = errorData.message || JSON.stringify(errorData); // Customize based on your API's error structure
        } catch (errorParsing) {
          console.error("Error parsing error response:", errorParsing);
        }
      }

      throw {
        message: `apiRequest: Network response was not ok: ${response.status} ${response.statusText}`,
        errorMsg: errorMsg,
        response: response,
      };
    }

    // Handle 204 No Content specifically
    if (response.status === 204) {
      return { status: 204, message: "Operation successful" };
    }

    // For other successful responses, attempt to parse JSON
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return response.json();
    } else {
      console.log("Response is not JSON, returning raw response");
      return response;
    }
  } catch (error) {
    console.error("Error in apiRequest:", error);
    throw error; // Rethrow the error instead of returning null
  }
};

export const handleTableData = (
  token: string,
  pageData: PageData,
  operation: string,
  { data, replacements = {}, extraHeaders = {}, extraOptions = {} }: TableDataOptions,
) => {
  if (!pageData.Endpoints) {
    throw new Error("No endpoints defined in pageData");
  }

  let endpoint;
  switch (operation) {
    case "create":
      endpoint = pageData.Endpoints.find((e) => e.method === "post");
      break;
    case "update":
      endpoint =
        pageData.Endpoints.find((e) => e.method === "put") || pageData.Endpoints.find((e) => e.method === "patch");
      replacements = { ...replacements, itemId: data.id };
      break;
    case "delete":
      endpoint = pageData.Endpoints.find((e) => e.method === "delete");

      if (!endpoint) {
        const getEndpoint = pageData.Endpoints.find((e) => e.method === "get" || e.method === "put");
        if (getEndpoint) {
          endpoint = { ...getEndpoint, method: "delete" };
        }
      }
      break;

    default:
      throw new Error(`Unsupported operation: ${operation}`);
  }
  if (!endpoint) {
    throw new Error(`No suitable endpoint found for operation: ${operation}`);
  }
  return apiRequest(token, endpoint, replacements, data, extraHeaders, extraOptions, pageData);
};

// useCustomMutation.js
export const useCustomMutation = (
  token: string,
  pageData: PageData,
  operation: string,
  handleTableData: (token: string, pageData: PageData, operation: string, data: any) => Promise<any>,
  callbackOptions: Partial<CallBackOptions> = {},
) => {
  const { onSuccessCallback, onErrorCallback, onMutateCallback, onSettledCallback } = callbackOptions;

  return useMutation({
    mutationFn: (data: any) => {
      return handleTableData(token, pageData, operation, data);
    },
    onSuccess: (data: MutationData, variables: MutationVariables, context: MutationContext) => {
      if (onSuccessCallback) onSuccessCallback(data, variables, context);
    },
    onError: (error: MutationError, variables: MutationVariables, context: MutationContext) => {
      if (error?.response?.status === 204) {
        if (onSuccessCallback) onSuccessCallback(error.response, variables, context);
      } else {
        if (onErrorCallback) onErrorCallback(error, variables, context);
      }
    },
    onMutate: async (variables: MutationVariables) => {
      if (onMutateCallback) await onMutateCallback(variables);
    },
    onSettled: (
      data: MutationData | undefined,
      error: MutationError | null,
      variables: MutationVariables,
      context: MutationContext,
    ) => {
      if (onSettledCallback) onSettledCallback(data, error, variables, context);
    },
  });
};

export const useApiToken = () => {
  const { getAccessTokenSilently } = useAuth();
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  return token;
};

export async function fetchDirectly(args: FetchDirectlyArgs, params?: FetchDirectlyParams) {
  let paramsString;

  if (params) {
    if (params.name === "*") {
      paramsString = "";
    } else {
      const searchParams = new URLSearchParams(Object.entries(params));
      paramsString = "?" + searchParams.toString();
    }
  }

  let response = await fetch(
    `${API_BASE}/${
      args?.fullUrl ?? `v1/sandbox/${args?.sandboxId}/${args?.endpoint}${args?.dataId ? "/" + args?.dataId : ""}`
    }${paramsString ?? ""}`,
    {
      method: args?.method,
      headers: new Headers({
        Authorization: `Bearer ${args?.token}`,
        "Content-Type": "application/json",
      }),
      body: args.body ? JSON.stringify(args.body) : null,
    },
  );
  if (response?.ok) {
    return response.status === 204 ? [] : await response.json();
  } else {
    let newError = new Error("Something went wrong") as ApiError;
    let error_msg = await response.json();

    if (!Object.hasOwn(error_msg, "desc")) {
      newError.response = { data: { desc: JSON.stringify(error_msg) } };
    } else {
      newError.response = { data: error_msg };
    }

    throw newError;
  }
}

export function getErrorMessage(error: ApiError, columns: any) {
  const baseErrorMsg = extractBaseErrorMessage(error);
  const detailedErrorMsg = extractDetailedErrorMessage(error, columns);

  return detailedErrorMsg || baseErrorMsg;
}

function extractBaseErrorMessage(error: ApiError) {
  let errorData;
  try {
    errorData = error?.response?.data;
    if (!errorData && error?.errorMsg) {
      errorData = JSON.parse(error.errorMsg);
    }
  } catch {
    errorData = error?.message;
  }

  if (!errorData) return null;

  return errorData.desc || errorData.detail || errorData;
}

function extractDetailedErrorMessage(error: ApiError, columns: any) {
  let serverErrorAltDetail;
  try {
    serverErrorAltDetail = error?.response?.data?.detail;
    if (!serverErrorAltDetail && error?.errorMsg) {
      serverErrorAltDetail = JSON.parse(error.errorMsg)?.detail;
    }
  } catch (error) {
    console.log("error parsing detail", { serverErrorAltDetail }, error);
  }
  if (!serverErrorAltDetail) {
    let serverErrorAltDesc = error?.response?.data?.desc;
    if (serverErrorAltDesc && typeof serverErrorAltDesc == "string") {
      try {
        const descJson = JSON.parse(serverErrorAltDesc)?.detail;
        serverErrorAltDetail = descJson;
      } catch {}
    }
  }

  if (!serverErrorAltDetail) {
    return null;
  }
  let msg = serverErrorAltDetail[0]?.msg;
  const type = serverErrorAltDetail[0]?.type;
  const loc = serverErrorAltDetail[0]?.loc;

  if (type && loc && loc.length > 0) {
    msg = type === "value_error.any_str.min_length" ? "value required" : msg;
    let suffix = loc[loc.length - 1];

    for (const col of columns) {
      if (col.field === suffix) {
        suffix = col.headerName;
        break;
      }
    }
    msg = `${msg}: ${suffix}`;
    msg = msg.replaceAll("_id", "");
  }
  return msg;
}

export function usePortalQuery({
  schema,
  token,
  replacements = {},
  data = {},
  method = "get",
  extraOptions = { queryParams: "" },
  configs = {
    version: "v1",
    schemas: undefined,
  },
  newModel = false,
}: PortalQueryOptions) {
  return useQuery({
    queryKey: [schema, replacements],
    queryFn: () => {
      // In the definitions file, there are often multiple
      // GET endpoints, one for a list, one for a single item.
      // This function finds the endpoint that contains all the
      // replacement keys in the path.
      const replacementKeys = Object.keys(replacements);
      const endpoint = assertedDefinitions.schemas?.[schema]?.endpoints.find(
        (ep) =>
          ep.method === method.toLowerCase() &&
          ep.path.includes(configs?.version ?? "v1") &&
          !ep.path.includes("/account/me") &&
          (replacementKeys.length > 0 ? replacementKeys.some((key) => ep.path.includes(`{${key}}`)) : true),
      );

      let extraOptionsLocal = {
        queryParams: {
          ...(configs?.schemas?.GETparams && typeof configs.schemas.GETparams === "object"
            ? configs.schemas.GETparams
            : {}),
          ...(typeof extraOptions?.queryParams === "object" ? extraOptions.queryParams : {}),
        },
      };
      if (!endpoint) {
        throw new Error(`No endpoint found for method: ${method}`);
      }
      return apiRequest(token, endpoint, replacements, data, null, extraOptionsLocal);
    },
    staleTime: 30000,
    enabled: !!replacements && !!token && !newModel && !!schema,
    placeholderData: keepPreviousData,
  });
}

export function usePortalMutation({
  // queryClient,
  schema,
  token,
  replacements,
  configs,
  method = "put",
  extraHeaders = {},
  extraOptions = { queryParams: "" },
  onSuccessCallback = () => {},
  onErrorCallback = () => {},
  onMutateCallback = () => Promise.resolve(),
  onSettledCallback = () => {},
}: PortalMutationOptions) {
  return useMutation({
    mutationFn: ({
      data = {},
      schemaOverride = undefined,
      configsOverride = undefined,
      replacementsOverride = undefined,
      extraOptionsOverride = { queryParams: "" },
    }: MutationFunctionArgs = {}) => {
      let endpoint;
      // POSTs often don't have replacements.
      const activeReplacements = replacementsOverride ?? replacements ?? {};
      const replacementKeys = activeReplacements ? Object.keys(activeReplacements) : [];

      if (method === "delete") {
        // For DELETE, use the GET or PUT endpoint as a base and modify it
        endpoint = assertedDefinitions.schemas?.[schemaOverride ?? schema]?.endpoints.find(
          (ep) =>
            (ep.method === "get" || ep.method === "put") &&
            ep.path.includes(configsOverride?.version ?? configs?.version ?? "v1") &&
            replacementKeys.some((key) => ep.path.includes(`{${key}}`)) &&
            !ep.path.includes("/account/me"),
        );
        if (endpoint) {
          endpoint = { ...endpoint, method: "delete" };
        }
      } else {
        endpoint = assertedDefinitions.schemas?.[schemaOverride ?? schema]?.endpoints.find(
          (ep) =>
            ep.method === method &&
            ep.path.includes(configsOverride?.version ?? configs?.version ?? "v1") &&
            !ep.path.includes("/account/me"),
        );
      }

      if (!endpoint) {
        throw new Error(`No endpoint found for method: ${method}`);
      }

      let extraOptionsLocal = {
        queryParams:
          method === "delete"
            ? (extraOptionsOverride?.queryParams ?? extraOptions?.queryParams ?? "")
            : method === "post"
              ? (configsOverride?.schemas?.POSTparams ?? configs?.schemas?.POSTparams)
              : (configsOverride?.schemas?.PUTparams ?? configs?.schemas?.PUTparams),
      };

      return apiRequest(token, endpoint, activeReplacements, data, extraHeaders, extraOptionsLocal);
    },
    onSuccess: (data, variables, context) => {
      if (onSuccessCallback) onSuccessCallback(data, variables, context);
    },
    onError: (error: ApiError, variables, context) => {
      if (error?.response?.status === 204) {
        // Handle the 204 No Content success case. Not sure why this happens
        onSuccessCallback(error.response, variables, context);
      } else {
        if (onErrorCallback) onErrorCallback(error);
      }
    },
    onMutate: async (variables) => {
      if (onMutateCallback) await onMutateCallback(variables);
    },
    onSettled: (data, error, variables, context) => {
      if (onSettledCallback) onSettledCallback(data, error, variables, context);
    },
  });
}

export function validateData(data: Record<string, any>, columns: any, rowKey: string) {
  return columns.reduce((acc: string[], field: any) => {
    const fieldValue = data[field.field];
    const isEmpty = fieldValue === null || fieldValue === undefined || fieldValue.toString().trim() === "";
    const isRequired = field.required && field.field !== rowKey;

    if (isRequired && isEmpty) {
      acc.push(`${field.headerName}: Required`);
    }

    if (field.type === "json" && fieldValue) {
      try {
        JSON.stringify(data[field.field]);
      } catch (error) {
        acc.push(`${field.headerName}: Invalid JSON`);
      }
    }
    return acc;
  }, []);
}

export const fetchTableData = async (
  token: string,
  idKey: string,
  get_endpoint: Endpoint,
  replacements: Replacements,
  searchValue: string,
  value: any,
  options: any[] = [],
) => {
  const queryParams = new URLSearchParams();
  const normalizedValue = Array.isArray(value) ? value : [value].filter(Boolean);

  // Add idKeys to queryParams if no options are available
  if (!options.length && !normalizedValue.some((val) => options.some((option) => option?.id === val?.id))) {
    normalizedValue.forEach((item) => {
      const ids = Array.isArray(item?.id) ? item.id : [item?.id];
      ids.forEach((id: string) => id && queryParams.append(idKey + "s", id));
    });
  }

  if (searchValue) {
    queryParams.append("name", searchValue);
  }

  // Add fixed query parameters
  queryParams.append("expand", "*");
  queryParams.append("sort_by", idKey);
  queryParams.append("sort_order", "asc");
  queryParams.append("cursor", "0");
  queryParams.append("page_size", options.length ? 50 : normalizedValue[0]?.id?.length || 50);

  return apiRequest(token, get_endpoint, replacements, {}, {}, { queryParams: queryParams.toString() });
};

export const fetchDynamicSelectData = async (
  token: string,
  idKey: string,
  get_endpoint: Endpoint,
  replacements: Replacements,
  searchValue: string,
  // Why is value used?
  value: any,
  selectedIds: string[] = [],
) => {
  if (value) {
    console.log(value);
  }
  const queryParams = new URLSearchParams();

  // Add selectedIds to queryParams
  selectedIds.forEach((id) => {
    if (id) {
      queryParams.append(`${idKey}s`, id);
    }
  });

  if (searchValue) {
    queryParams.append("name", searchValue);
  }

  // Add fixed query parameters
  queryParams.append("expand", "*");
  queryParams.append("sort_by", idKey);
  queryParams.append("sort_order", "asc");
  queryParams.append("cursor", "0");
  queryParams.append("page_size", "50");

  return apiRequest(token, get_endpoint, replacements, {}, {}, { queryParams: queryParams.toString() });
};
