import { useState, useContext, useEffect, useRef, useMemo } from "react";
import { createPortal } from "react-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { AnimatePresence, motion } from "framer-motion";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import "react-day-picker/style.css";
import AccountContext from "contexts/AccountContext";
import { useLayout } from "contexts/LayoutContext";
import { useAuth } from "contexts/AuthContext";
import RightDrawer from "components/common/RightDrawer";
import { apiRequest, getErrorMessage } from "common/apiUtils";
import DataTable from "components/common/datatable";
import LoadingMask from "components/common/DynamicLoadingMask";
import MenuBar from "components/common/MenuBar";
import DropdownMenu from "components/common/DropdownMenu";
import Form from "components/common/Form";
import ImportSchedule from "./ImportSchedule.js";
import PublishingOverrides from "views/environment/PublishingOverrides";
import { CloseIcon, CalendarIcon, ListIcon, OpenDrawerIcon, PlusIcon, DownloadIcon, UploadIcon } from "assets/svgs";
import Notification from "components/common/Notification";
import CopyScheduleModal from "./CopyScheduleModal";

import CONFIG from "./scheduler.config.js";
import definitions from "common/definitions.json";
dayjs.extend(localizedFormat);

export default function Scheduler() {
  const queryClient = useQueryClient();
  const defaultClassNames = getDefaultClassNames();

  const tabs = Object.keys(CONFIG.tabs);
  const defaultTab = useMemo(() => Object.keys(CONFIG.tabs).find((key) => CONFIG.tabs[key]?.default), [CONFIG]);

  const { defaultDrawerWidth, tableSize } = useLayout();
  const columnHelper = createColumnHelper();

  const { currentStateData, permissionData, hasPermission } = useContext(AccountContext);
  const { getAccessToken } = useAuth();

  const [modsEditorOpen, setModsEditorOpen] = useState(false);
  const [viewType, setViewType] = useState("list");
  const [drawerLoadingMessage, setDrawerLoadingMessage] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(0);
  const [selectedRow, setSelectedRow] = useState();
  const [rowSelectionModel, setRowSelectionModel] = useState({});
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [newModel, setNewModel] = useState();
  const [columnVisibility, setColumnVisibility] = useState({
    schedule_id: false,
    name: true,
    description: true,
    start_datetime: true,
  });

  const [formIsDirty, setFormIsDirty] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);

  // Calendar Management
  const [selectedDay, setSelectedDay] = useState();
  const [month, setMonth] = useState(dayjs().toDate());

  const [notification, setNotification] = useState({
    message: "",
    severity: "info", // "success", "error", "warning", "info"
  });

  const clearNotification = () => {
    setNotification({ message: "", severity: "info" });
  };

  const editPermitted =
    permissionData && currentStateData?.product?.productId
      ? hasPermission(
          "product:config:edit",
          "product",
          currentStateData?.product?.productId,
          currentStateData,
          permissionData,
        )
      : false;

  const eventInTheFuture = useMemo(
    () => dayjs(selectedRow?.original?.start_datetime ?? selectedRow?.start_datetime).valueOf() > Date.now(),
    [selectedRow],
  );

  const readPermitted =
    permissionData && currentStateData?.product?.productId
      ? hasPermission(
          "product:config:view",
          "product",
          currentStateData?.product?.productId,
          currentStateData,
          permissionData,
        ) ||
        hasPermission(
          "product:config:edit",
          "product",
          currentStateData?.product?.productId,
          currentStateData,
          permissionData,
        )
      : false;

  let isResizing = useRef(false);

  const drawerButtonsMap = [
    {
      name: "Close Drawer",
      icon: <OpenDrawerIcon className="rotate-180" />,
      fn: setDrawerWidth,
      args: [0],
    },
  ];

  const buttonsMap = [
    {
      name: "Import Schedule",
      icon: (
        <div className="rounded p-1 mx-1 hover:bg-zinc-500/30">
          <DownloadIcon className="w-4 h-4 rounded" />
        </div>
      ),
      fn: () => setImportModalOpen(true),
    },
    {
      name: "Calendar View",
      icon: (
        <div className={`rounded p-1 mx-1 ${viewType === "calendar" ? "bg-zinc-500/30" : "hover:bg-zinc-500/30"}`}>
          <CalendarIcon className={`w-4 h-4 rounded ${viewType === "calendar" ? "fill-violet-400" : ""}`} />
        </div>
      ),
      fn: setViewType,
      args: ["calendar"],
    },
    {
      name: "List View",
      icon: (
        <div className={`rounded p-1 ml-1 mr-2 ${viewType === "list" ? "bg-zinc-500/30" : "hover:bg-zinc-500/30"}`}>
          <ListIcon className={`w-4 h-4 rounded ${viewType === "list" ? "fill-violet-400" : ""}`} />
        </div>
      ),
      fn: setViewType,
      args: ["list"],
    },
    {
      name: "Table Size",
      icon: <DropdownMenu type="TableSize" />,
    },
   /*  {
      name: "Open Drawer",
      icon: <OpenDrawerIcon />,
      fn: setDrawerWidth,
      args: [350],
    }, */
  ];

  const columns = useMemo(
    () =>
      CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.map((field, i) => {
        return field.field === "name"
          ? columnHelper.accessor(field.field, {
              cell: (info) => info.getValue(),
              header: () => <span>{field.headerName}</span>,
              minSize: 250,
              maxSize: 500,
            })
          : field.type === "datetime"
            ? columnHelper.accessor(field.field, {
                cell: (info) => dayjs(info.getValue()).format("llll"),
                header: () => <span>{field.headerName}</span>,
                size: Number.MAX_SAFE_INTEGER,
              })
            : columnHelper.accessor(field.field, {
                cell: (info) => info.getValue(),
                header: () => <span>{field.headerName}</span>,
                size: Number.MAX_SAFE_INTEGER,
              });
      }),
    [activeTab],
  );

  function handleOutsideClick(e) {
    if (e.target.classList.contains("mods-editor")) {
      if (formIsDirty) {
        setConfirmationModalOpen(true);
      } else setModsEditorOpen(false);
    }
  }

  const overlaysQuery = useQuery({
    queryKey: ["overlays", currentStateData?.product?.productId],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: "/v1/product/{product_id}/environment/{environment_id}/overlay", method: "GET" },
        {
          product_id: currentStateData?.product?.productId,
          environment_id: currentStateData?.environment?.environmentId,
        },
      ),
    enabled: !!currentStateData?.product?.productId && !!currentStateData?.environment?.environmentId,
  });

  async function enableOverlay() {
    const overlayResponse = await apiRequest(
      getAccessToken(),
      {
        path: "/v1/product/{product_id}/overlay",
        method: "POST",
      },
      {
        product_id: currentStateData?.product?.productId,
      },
      {
        name: `Default Publishing Overlay for product ${currentStateData?.product?.productId}, environment ${currentStateData?.environment?.environmentId}`,
      },
    );

    const environmentOverlayResponse = await apiRequest(
      getAccessToken(),
      {
        path: "/v1/product/{product_id}/environment/{environment_id}/overlay",
        method: "POST",
      },
      {
        product_id: currentStateData?.product?.productId,
        environment_id: currentStateData?.environment?.environmentId,
      },
      {
        overlay_id: overlayResponse?.overlay_id,
      },
    );

    if (environmentOverlayResponse?.overlay_id) {
      queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === "overlays" && query.queryKey[1] === currentStateData?.product?.productId) ||
          (query.queryKey[0] === "mods" && query.queryKey[1] === overlaysQuery.data?.[0]?.overlay_id),
      });
    }
  }

  const environmentQuery = useQuery({
    queryKey: ["environment", currentStateData?.environment?.environmentId],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        { path: `/v1/product/{product_id}/environment/{environment_id}`, method: "GET" },
        {
          product_id: currentStateData?.product?.productId,
          environment_id: currentStateData?.environment?.environmentId,
        },
      ),
    enabled: !!currentStateData?.product?.productId && !!currentStateData?.environment?.environmentId,
  });

  const sandboxQuery = useQuery({
    queryKey: ["sandbox", environmentQuery.data?.base_sandbox_id],
    queryFn: () =>
      apiRequest(
        getAccessToken(),
        {
          path: `/v1/org/{org_identifier}/product/{product_identifier}/sandbox/{sandbox_id}`,
          method: "GET",
        },
        {
          org_identifier: currentStateData?.org?.orgId,
          product_identifier: currentStateData?.product?.productId,
          sandbox_id: environmentQuery.data?.base_sandbox_id,
        },
      ),
    enabled:
      !!currentStateData?.org?.orgId &&
      !!currentStateData?.product?.productId &&
      !!environmentQuery?.data?.base_sandbox_id,
  });

  const replacements = useMemo(() => {
    return {
      sandbox_id: environmentQuery?.data?.base_sandbox_id,
      product_id: currentStateData?.product?.productId,
      org_id: currentStateData?.org?.orgId,
      environment_id: currentStateData?.environment?.environmentId,
    };
  }, [environmentQuery?.data?.base_sandbox_id, currentStateData?.product?.productId, currentStateData?.org?.orgId]);

  function handleAdd(date) {
    const newModelObj = {
      fields: CONFIG?.tabs[activeTab]?.editor?.["Info"]?.fields.reduce((acc, field) => {
        acc[field.field] = "";
        return acc;
      }, {}),
      tab: "Info",
    };
    setSelectedRow();
    setRowSelectionModel({});

    const isEvent = date && (date.target || date.type);
    const actualDate = isEvent ? null : date;

    if (actualDate) {
      newModelObj.fields.start_datetime = dayjs(actualDate).format("YYYY-MM-DDTHH:mm:ss");
    } else if (selectedDay) {
      newModelObj.fields.start_datetime = dayjs(selectedDay).format("YYYY-MM-DDTHH:mm:ss");
    } else if (!selectedDay && month) {
      newModelObj.fields.start_datetime = dayjs(month).format("YYYY-MM-DDTHH:mm:ss");
    }
    setNewModel({ ...newModelObj });
  }

  async function fetchData(activeTab, replacements, config) {
    const endpoint = definitions.schemas?.[config.tabs[activeTab]?.schema]?.endpoints.find((e) => e.method === "get");
    let extraOptions = config.tabs[activeTab]?.GETparams
      ? {
          queryParams: config.tabs[activeTab]?.GETparams,
        }
      : undefined;

    return apiRequest(await getAccessToken(), endpoint, replacements, null, null, extraOptions);
  }

  const schedulesList = useQuery({
    queryKey: [activeTab, currentStateData?.environment?.environmentId],
    queryFn: () => fetchData(activeTab, replacements, CONFIG),
    enabled:
      !!replacements?.org_id &&
      !!replacements?.sandbox_id &&
      !!replacements?.environment_id &&
      !!replacements?.product_id,
    onError: (error) => {
      const errorMsg = getErrorMessage(error, CONFIG?.tabs[activeTab]?.editor?.fields);
      setError(errorMsg);
    },
    staleTime: 30000,
  });

  function filterMonth(schedule) {
    return dayjs(schedule.start_datetime).month() === dayjs(month).month();
  }

  function formatByDay(acc, schedule) {
    const day = dayjs(schedule.start_datetime).format("D--ddd");
    if (acc[day]) {
      acc[day].push(schedule);
    } else {
      acc[day] = [schedule];
    }
    return acc;
  }

  function goToToday() {
    setSelectedDay(dayjs().toDate());
    setMonth(dayjs().toDate());
  }

  function eventExists(date) {
    if (dayjs(date).isSame(dayjs(selectedDay), "day")) {
      return false;
    }
    return schedulesList?.data?.some((schedule) => {
      const scheduleDate = dayjs(schedule.start_datetime);
      const compareDate = dayjs(date);

      return scheduleDate.isSame(compareDate, "day");
    });
  }

  useEffect(() => {
    if (newModel || Object.keys(rowSelectionModel).length > 0) {
      setDrawerWidth(defaultDrawerWidth);
    } else {
      setDrawerWidth(0);
    }
    if (selectedRow) {
      setNewModel();
    }
  }, [selectedRow, newModel, rowSelectionModel]);

  useEffect(() => {
    setDrawerWidth(0);
    setNewModel();
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [CONFIG, defaultTab]);

  const loadMessage = schedulesList?.isError
    ? `Unable to retrieve data. ${schedulesList?.error?.message}`
    : "Retrieving the latest data, please wait...";

  return overlaysQuery?.data?.length === 0 ? (
    <div className="p-10 max-w-[700px] text-base leading-tight">
      <p className="text-zinc-300 mb-6">
        It looks like you don't have any overlays set up yet for this environment. This feature allows for your team to
        easily override values that exist in the sandbox that this environment uses. Enabling an overlay will invisibly
        generate a copy of the sandbox, but with the values provided in Publishing Overrides, if any exist. When an
        event is executed in the Scheduler, the values in Publishing Overrides will be upadted. The game will
        automatically point to this generated sandbox. There will be no difference in game performance.
      </p>
      <p className="mb-6">Would you like to enable overlays and use the Scheduler?</p>
      <div
        onClick={enableOverlay}
        className="bg-violet-600 text-white font-medium rounded-sm text-center inline-flex items-center z-10 py-2 px-3 gap-2 transition-colors duration-200 ease-in-out hover:bg-violet-500 cursor-pointer"
      >
        Enable Overlays
      </div>
    </div>
  ) : (
    <div className="relative flex z-10 h-[calc(100vh-50px)] overflow-hidden">
      {drawerLoadingMessage && (
        <div className="absolute top-0 left-0 z-20 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div>
            <LoadingMask size={100} loadMessage={drawerLoadingMessage} />
          </div>
        </div>
      )}
      <div
        style={{
          width: `calc(100% - ${drawerWidth}px)`,
          transition: isResizing.current ? "none" : "width 0.3s ease-in-out",
        }}
      >
        <MenuBar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          buttons={buttonsMap}
          setSelectedRow={setSelectedRow}
          setRowSelectionModel={setRowSelectionModel}
        />
        {schedulesList?.isPending && !schedulesList?.error ? (
          <div className="mt-20 flex-1">
            <LoadingMask loadMessage={loadMessage} />
          </div>
        ) : viewType === "list" ? (
          <DataTable
            data={schedulesList?.data}
            columns={columns}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            columnsWithSort={["name", "description", "start_datetime"]}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            setRowSelectionModel={setRowSelectionModel}
            rowSelectionModel={rowSelectionModel}
            defaultSortKey={"start_datetime"}
            handleAdd={handleAdd}
            rowId={CONFIG.tabs[activeTab]?.rowId}
            readPermitted={readPermitted}
            editPermitted={editPermitted}
            className="h-[calc(100vh-50px)] overflow-y-auto pb-48"
          />
        ) : (
          <div>
            <div className="flex justify-end p-2">
              {editPermitted && (
                <button
                  onClick={handleAdd}
                  className="bg-violet-600 text-white font-medium rounded-sm text-sm text-center inline-flex items-center z-10 p-1 gap-2 transition-colors duration-200 ease-in-out hover:bg-violet-500"
                >
                  <PlusIcon className="w-4 h-4 rounded fill-white" />
                </button>
              )}
            </div>
            <div className="flex overflow-x-scroll h-[calc(100vh-100px)]">
              <div className="sticky top-0 left-0">
                <DayPicker
                  mode="single"
                  selected={selectedDay}
                  onSelect={setSelectedDay}
                  onMonthChange={setMonth}
                  month={month}
                  modifiers={{
                    eventScheduled: (date) => eventExists(date),
                  }}
                  modifiersClassNames={{
                    eventScheduled: `bg-violet-600/50 text-white rounded`,
                  }}
                  onDayClick={(date) => handleAdd(date)}
                  classNames={{
                    day: `${defaultClassNames.day} text-white/70 transition-all hover:bg-violet-500/50 rounded`,
                    today: `border border-violet-400 rounded block`,
                    selected: `bg-violet-500 text-white rounded`,
                    root: `${defaultClassNames.root} shadow-lg px-5 [&_table]:border-separate [&_table]:border-spacing-[1px]`,
                    chevron: `${defaultClassNames.chevron} fill-violet-500`,
                  }}
                />
              </div>
              <div className="p-5 min-w-[500px] mb-5">
                <div className="flex justify-start items-center gap-4 mb-4">
                  <button
                    onClick={goToToday}
                    className="text-white bg-violet-600 rounded py-1 px-2 hover:bg-violet-500 transition-colors font-medium"
                  >
                    Go To Today
                  </button>
                  {/* <p className={`text-white font-bold text-base`}>{dayjs(month).format("MMMM YYYY")}</p> */}
                </div>
                <div className="flex border-b border-white/10 py-2 text-left text-bold px-2">
                  <div className="text-left min-w-28 font-bold">Date</div>
                  <div className="text-left min-w-28 font-bold">Time</div>
                  <div className="text-left min-w-48 font-bold">Event Name</div>
                  <div className="text-left min-w-56 font-bold">Description</div>
                </div>
                {/* This converts the array of schedule data from the api into an object with the date as the key and the schedules as the value */}
                {Object.entries(
                  schedulesList?.data
                    .filter(filterMonth)
                    .sort((a, b) => dayjs(a.start_datetime).diff(dayjs(b.start_datetime)))
                    .reduce(formatByDay, {}),
                ).map(([day, schedules], i) => (
                  <div key={day} className="flex w-full p-2 gap-10 border-b border-white/10 last:border-b-0">
                    <div className="py-1 min-w-16 uppercase flex justify-between items-start">
                      {day.split("--").map((segment, i) => {
                        return i === 0 ? (
                          <span key={segment + i} className={i === 0 ? "font-bold text-xl text-center" : ""}>
                            {segment}
                          </span>
                        ) : (
                          <span className="pt-1" key={segment + i}>
                            {segment}
                          </span>
                        );
                      })}
                    </div>
                    <div className="flex-1">
                      {schedules.map((schedule) => (
                        <div
                          key={schedule.schedule_id}
                          className={`flex items-start hover:bg-white/10 cursor-pointer rounded p-2 w-full mb-1 last:mb-0 ${
                            selectedRow?.schedule_id === schedule.schedule_id ? "bg-white/5" : ""
                          } ${dayjs(schedule.start_datetime).isBefore(dayjs()) ? "opacity-50" : ""}`}
                          onClick={() => {
                            setNewModel();
                            setSelectedRow(schedule);
                            setRowSelectionModel({ [schedule.id]: true });
                          }}
                        >
                          <p className="min-w-28 pr-4">{dayjs(schedule.start_datetime).format("h:mma")}</p>
                          <p className="min-w-48 w-48 pr-4">{schedule.name}</p>
                          <p className="min-w-56 max-w-96">{schedule.description}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <RightDrawer setWidthFn={setDrawerWidth} width={drawerWidth} isResizing={isResizing}>
        <div>
          {(!!newModel || selectedRow) && (
            <Form
              item={newModel ?? selectedRow.original ?? selectedRow}
              setRowSelectionModel={setRowSelectionModel}
              setDrawerLoadingMessage={setDrawerLoadingMessage}
              newModel={newModel}
              config={CONFIG?.tabs[activeTab]}
              tab={activeTab}
              setSelectedRow={setSelectedRow}
              buttonsMap={drawerButtonsMap}
              readPermitted={readPermitted}
              editPermitted={editPermitted && (newModel || eventInTheFuture)}
              topBanner={
                !eventInTheFuture && !newModel ? (
                  <div className="bg-red-500/50 p-2 text-center">
                    <p className="text-white/70">This event is in the past. You cannot edit it.</p>
                  </div>
                ) : undefined
              }
              additionalButtons={
                eventInTheFuture
                  ? [
                      {
                        name: "Edit Event Details",
                        fn: setModsEditorOpen,
                        args: [true],
                      },
                      {
                        name: "Copy To",
                        fn: setCopyModalOpen,
                        args: [true],
                      },
                    ]
                  : []
              }
              actionsOnCreate={[
                {
                  fn: setModsEditorOpen,
                  args: [true],
                },
              ]}
            />
          )}
        </div>
      </RightDrawer>
      {modsEditorOpen &&
        createPortal(
          <AnimatePresence>
            <motion.div
              className="mods-editor fixed top-0 left-0 w-full flex justify-center items-center z-[100] bg-black bg-opacity-80 h-screen overflow-y-hidden"
              onClick={handleOutsideClick}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <AnimatePresence>
                <motion.div
                  className="w-5/6 h-[70vh]"
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0.8 }}
                >
                  <div className="text-white/70 flex justify-end pb-2 -mr-2">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        if (formIsDirty) {
                          setConfirmationModalOpen(true);
                        } else setModsEditorOpen(false);
                      }}
                    >
                      <CloseIcon className="w-6 h-6" />
                    </span>
                  </div>
                  <div className="bg-opacity-80 relative overflow-y-hidden bg-zinc-900 border border-white/10 rounded-sm h-[70vh]">
                    <div
                      className={`text-white/70 flex justify-between p-2 w-full ${tableSize} w-full rounded-tr-sm rounded-tl-sm overflow-y-hidden`}
                    >
                      <p className="font-medium">
                        <span className="font-bold">Event Name:</span>{" "}
                        {selectedRow?.original?.name ?? selectedRow?.name}
                      </p>
                      <p className="font-medium">
                        <span className="font-bold">Start Time:</span>{" "}
                        {dayjs(selectedRow?.original?.start_datetime ?? selectedRow?.start_datetime).format("llll")}
                      </p>
                    </div>
                    <PublishingOverrides
                      scheduleId={selectedRow?.original?.schedule_id ?? selectedRow?.schedule_id}
                      className={`rounded-sm`}
                      setFormIsDirty={setFormIsDirty}
                    />
                    {confirmationModalOpen && (
                      <div className="absolute bottom-0 left-0 w-full h-full flex justify-center items-center text-white/70 z-[101] bg-zinc-900/50">
                        <div className="w-96 h-72 bg-zinc-900/90 rounded border border-white/10 p-6 flex items-center justify-center">
                          <div>
                            <p className={`text-white/70 mb-10 ${tableSize === "small" ? "text-sm" : "text-base"}`}>
                              You have unsaved changes. Are you sure you want to close the window?
                            </p>
                            <div className="flex justify-center gap-2">
                              <button
                                className={`rounded border border-white/10 p-2 text-white font-medium transition-colors bg-rose-800 hover:bg-pink-950
                                ${tableSize === "small" ? "text-sm" : "text-base"}`}
                                onClick={() => {
                                  setModsEditorOpen(false);
                                  setFormIsDirty(false);
                                  setConfirmationModalOpen(false);
                                }}
                              >
                                Close and Discard Changes
                              </button>
                              <button
                                className={`rounded border border-white/10 p-2 bg-violet-800 hover:bg-violet-700 text-white font-medium transition-colors ${tableSize === "small" ? "text-sm" : "text-base"}`}
                                onClick={() => {
                                  setConfirmationModalOpen(false);
                                }}
                              >
                                Go Back
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </motion.div>
              </AnimatePresence>
            </motion.div>
          </AnimatePresence>,
          document.body,
        )}
      {importModalOpen && (
        <ImportSchedule
          isOpen={importModalOpen}
          onClose={() => setImportModalOpen(false)}
          productId={currentStateData?.product?.productId}
          environmentId={currentStateData?.environment?.environmentId}
          onSuccess={(message) => {
            setNotification({
              message: message || "Schedule imported successfully!",
              severity: "success",
            });
            queryClient.invalidateQueries({
              queryKey: [activeTab, currentStateData?.environment?.environmentId],
            });
          }}
          onError={(error) => {
            setNotification({
              message: error || "Failed to import schedule",
              severity: "error",
            });
          }}
        />
      )}
      {exportModalOpen && (
        <ExportSchedule
          isOpen={exportModalOpen}
          onClose={() => setExportModalOpen(false)}
          productId={currentStateData?.product?.productId}
          currentEnvironmentId={currentStateData?.environment?.environmentId}
          onSuccess={(message) => {
            setNotification({
              message: message || "Schedule exported successfully!",
              severity: "success",
            });
            queryClient.invalidateQueries({
              queryKey: [activeTab, currentStateData?.environment?.environmentId],
            });
          }}
          onError={(error) => {
            setNotification({
              message: error || "Failed to export schedule",
              severity: "error",
            });
          }}
        />
      )}
      {copyModalOpen && selectedRow && (
        <CopyScheduleModal
          isOpen={copyModalOpen}
          onClose={() => setCopyModalOpen(false)}
          productId={currentStateData?.product?.productId}
          currentEnvironmentId={currentStateData?.environment?.environmentId}
          scheduleId={selectedRow?.original?.schedule_id ?? selectedRow?.schedule_id}
          onSuccess={(message) => {
            setNotification({
              message: message || "Schedule copied successfully!",
              severity: "success",
            });
            queryClient.invalidateQueries({
              queryKey: [activeTab, currentStateData?.environment?.environmentId],
            });
          }}
          onError={(error) => {
            setNotification({
              message: error || "Failed to copy schedule",
              severity: "error",
            });
          }}
        />
      )}
      {notification.message && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          clearMessage={clearNotification}
        />
      )}
    </div>
  );
}
