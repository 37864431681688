import React, { useEffect, useMemo } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import Layout from "layouts/Layout";
import { AccountContextProvider } from "contexts/AccountContext";
import { RHMessageProvider } from "contexts/messagebar/RHMessageContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DataProvider } from "contexts/DataContext";

import RHKVConfigPage from "views/sandbox/RHKeyValueConfigPage";
import RHOrgPage from "views/org/RHOrgPage";
import RHProfilePage from "views/RHProfilePage";
import RHProductPage from "views/product/RHProductPage";
import RHEnvironmentPage from "views/environment/RHEnvironmentPage";
import RHEnvironmentDetailPage from "views/environment/RHEnvironmentDetailPage";
import PublishingOverrides from "views/environment/PublishingOverrides";
import Scheduler from "views/environment/Scheduler";
import Tableau from "views/environment/Tableau";

import RHSandboxPage from "views/sandbox/RHSandboxPage";
import RHOrgAnalytics from "views/org/RHOrgAnalyticsPage";
import RHOrgClusters from "views/org/RHOrgClustersPage";
import RHOrgApiDocsPage from "views/org/RHOrgApiDocsPage";
import RHPortalAdmin from "views/portalAdmin/RHPortalAdmin";
import RHOrgStructure from "views/portalAdmin/RHOrgStructure";
import ControlPanel from "./views/ControlPanel/ControlPanel";
import AdminDashboard from "./views/admin/AdminDashboard";
import RHPricePointPage from "views/sandbox/catalog/RHPricePointPage";

import RHPortalPermissions from "views/portalAdmin/RHPortalPermissions";

import ArmadaGeoLocations from "views/armada/ArmadaGeoLocations";
import ArmadaDCProviders from "views/armada/ArmadaDCProviders";
import ArmadaDCLocations from "views/armada/ArmadaDCLocations";
import ArmadaMachineImages from "views/armada/ArmadaMachineImages";
import ArmadaDCContracts from "views/armada/ArmadaDCContracts";
import ArmadaMachineClasses from "views/armada/ArmadaMachineClasses";
import ArmadaProductAssignments from "views/armada/ArmadaProductAssignments";
import ArmadaHostLabels from "views/armada/ArmadaHostLabels";
import ArmadaHosts from "views/armada/ArmadaHosts";
import ArmadaHome from "views/armada";
import ArmadaClientCredentials from "views/armada/ArmadaClientCredentials";
import UserManagement from "views/userManagement";
import RHProductPolicyPage from "views/product/RHProductPolicyPage";
import RHProductClientPage from "views/product/RHProductClientPage";
import ToolsPage from "views/Tools";
import RHDynamicPage from "./views/RHDynamicPage";
import DynamicLoadingMask from "components/common/DynamicLoadingMask";
import { LayoutProvider } from "./contexts/LayoutContext";
import ArmadaTerraform from "./views/terraform/ArmadaTerraform";
import ArmadaTerraformVMSize from "./views/terraform/ArmadaTerraformVmSize";
import TerraformHome from "./views/terraform";
import ArmadaTerraformVMDisk from "./views/terraform/ArmadaTerraformVmDisk";
import ArmadaTerraformVMRegion from "./views/terraform/ArmadaTerraformVmRegion";
import ArmadaTerraformVMOS from "./views/terraform/ArmadaTerraformVmOS";
import ArmadaTerraformVMRG from "./views/terraform/ArmadaTerraformVmRG";
import ArmadaTerraformJobRecord from "./views/terraform/ArmadaTerraformJobRecord";
import ArmadaTerraformVMSubnetRange from "./views/terraform/ArmadaTerraformVmSubnetRange";

// Create a client
const queryClient = new QueryClient();

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

export default function RHMainApp() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth();
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }

    const lock = document.createElement("meta");
    lock.name = "darkreader-lock";
    document.head.appendChild(lock);
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <DynamicLoadingMask loadMessage="" />;
  }

  if (!isAuthenticated) {
    // You might want to render null or some placeholder content here
    // while waiting for the redirect to happen
    return <DynamicLoadingMask loadMessage="" />;
  }

  return (
    <AccountContextProvider>
      <DataProvider>
        <QueryClientProvider client={queryClient}>
          <RHMessageProvider>
            <LayoutProvider>
              <Layout>
                <Switch>
                  <Route path="/tools">
                    <ToolsPage />
                  </Route>
                  <Route path="/admin/armada/dc-location">
                    <ArmadaDCLocations />
                  </Route>
                  <Route path="/admin/armada/dc-provider">
                    <ArmadaDCProviders />
                  </Route>
                  <Route path="/admin/armada/geo-location">
                    <ArmadaGeoLocations />
                  </Route>
                  <Route path="/admin/armada/machine-image">
                    <ArmadaMachineImages />
                  </Route>
                  <Route path="/admin/armada/dc-contract">
                    <ArmadaDCContracts />
                  </Route>
                  <Route
                    path="/admin/armada/machine-class/:machineClassId"
                    render={(props) => <ArmadaMachineClasses machineClassId={props.match.params.machineClassId} />}
                  />
                  <Route path="/admin/armada/machine-class">
                    <ArmadaMachineClasses />
                  </Route>
                  <Route path="/admin/armada/product-assignment">
                    <ArmadaProductAssignments />
                  </Route>
                  <Route path="/admin/armada/host-label">
                    <ArmadaHostLabels />
                  </Route>
                  <Route path="/admin/armada/host">
                    <ArmadaHosts />
                  </Route>
                  <Route path="/admin/armada/host-by-label">
                    <AdminDashboard
                      allColumns={useMemo(
                        () => [
                          { header: "Customer Org", accessorKey: "customer_org", labelFilter: "org" },
                          { header: "Cust Org ID", accessorKey: "cust_org_id", labelFilter: "org_id" },
                          { header: "Customer Product", accessorKey: "customer_product", labelFilter: "product" },
                          { header: "Cust Prod ID", accessorKey: "cust_prod_id", labelFilter: "product_id" },
                          {
                            header: "Prod Unique Name",
                            accessorKey: "prod_unique_name",
                            labelFilter: "product_unique_name",
                          },
                          {
                            header: "Customer Environment",
                            accessorKey: "customer_environment",
                            labelFilter: "environment",
                          },
                          { header: "Cust Env ID", accessorKey: "cust_env_id", labelFilter: "environment_id" },
                          { header: "Env Unique Name", accessorKey: "env_unique_name", labelFilter: "unique_name" },
                        ],
                        [],
                      )}
                      columns={(allColumns, hiddenColumns) => {
                        return allColumns
                          .filter((column) => !hiddenColumns.includes(column.accessorKey))
                          .map((column) => ({
                            ...column,
                            cell: ({ getValue }) => (
                              <Link to={`/admin/armada/host?${column.labelFilter}=${getValue(column.accessorKey)}`}>
                                {getValue(column.accessorKey)}
                              </Link>
                            ),
                          }));
                      }}
                    >
                      <h1 className="text-2xl font-bold mb-4">Armada Host by Label Dashboard</h1>
                      <p>Clicking on the value takes you to page which shows hosts filtered by that label.</p>
                    </AdminDashboard>
                  </Route>
                  <Route path="/admin/armada/client-credentials">
                    <ArmadaClientCredentials />
                  </Route>
                  <Route path="/admin/armada">
                    <ArmadaHome />
                  </Route>
                  <Route path="/admin/dashboard">
                    <AdminDashboard />
                  </Route>
                  <Route path="/admin/orgstructure">
                    <RHOrgStructure />
                  </Route>
                  <Route path="/admin/permissions">
                    <RHPortalPermissions />
                  </Route>
                  <Route path="/admin/terraform/terraform-portal">
                    <ArmadaTerraform />
                  </Route>
                  <Route path="/admin/terraform/vm-size">
                    <ArmadaTerraformVMSize />
                  </Route>
                  <Route path="/admin/terraform/vm-disk">
                    <ArmadaTerraformVMDisk />
                  </Route>
                  <Route path="/admin/terraform/vm-region">
                    <ArmadaTerraformVMRegion />
                  </Route>
                  <Route path="/admin/terraform/vm-os">
                    <ArmadaTerraformVMOS />
                  </Route>
                  <Route path="/admin/terraform/vm-rg">
                    <ArmadaTerraformVMRG />
                  </Route>
                  <Route path="/admin/terraform/vm-subnet">
                    <ArmadaTerraformVMSubnetRange />
                  </Route>
                  <Route path="/admin/terraform/job-record">
                    <ArmadaTerraformJobRecord />
                  </Route>
                  <Route path="/admin/terraform">
                    <TerraformHome />
                  </Route>
                  <Route path="/admin">
                    <RHPortalAdmin />
                  </Route>
                  <Route path="/profile">
                    <RHProfilePage />
                  </Route>
                  {/* <Route path="/org/:orgId/product/:productId/sandbox/:sandboxId/pricing-config">
                    <RHPricePointPage />
                  </Route> */}
                  <Route path="/org/:orgId/product/:productId/sandbox/:sandboxId/kv-editor">
                    <RHKVConfigPage />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/sandbox/:sandboxId/game-config">
                    <ControlPanel type="sandbox" />
                  </Route>
                  <Route
                    path="/org/:orgId/product/:productId/sandbox/:sandboxId/:apiId"
                    render={(props) => <RHDynamicPage page={props.match.params.apiId} />}
                  />

                  <Route path="/org/:orgId/product/:productId/policies">
                    <RHProductPolicyPage />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/clients">
                    <RHProductClientPage />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/environment/:environmentId/control-panel">
                    <ControlPanel />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/environment/:environmentId/files">
                    <RHDynamicPage />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/environment/:environmentId/options">
                    <RHEnvironmentDetailPage />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/environment/:environmentId/publishing-overrides">
                    <PublishingOverrides />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/environment/:environmentId/scheduler">
                    <Scheduler />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/environment/:environmentId/tableau">
                    <Tableau />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/environment/:environmentId">
                    <RHEnvironmentPage />
                  </Route>
                  <Route path="/org/:orgId/product/:productId/sandbox/:sandboxId">
                    <RHSandboxPage />
                  </Route>
                  <Route path="/org/:orgId/user-management/groups">
                    <UserManagement view="groups" />
                  </Route>
                  <Route path="/org/:orgId/user-management/users">
                    <UserManagement />
                  </Route>
                  <Route path="/org/:orgId/user-management">
                    <UserManagement />
                  </Route>
                  <Route path="/org/:orgId/analytics">
                    <RHOrgAnalytics />
                  </Route>
                  <Route path="/org/:orgId/clusters">
                    <RHOrgClusters />
                  </Route>
                  <Route path="/org/:orgId/apidocs">
                    <RHOrgApiDocsPage />
                  </Route>
                  <Route path="/org/:orgId/product/:productId">
                    <RHProductPage />
                  </Route>
                  <Route path="/org/:orgId">
                    <RHOrgPage />
                  </Route>
                  <Redirect to="/" />
                </Switch>
              </Layout>
            </LayoutProvider>
          </RHMessageProvider>
          {showDevtools && (
            <React.Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </React.Suspense>
          )}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </DataProvider>
    </AccountContextProvider>
  );
}
